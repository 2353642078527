import { FaUserTie } from "react-icons/fa6";

const Modal = ({
  title,
  titleIcon,
  children,
  modalOpen = false,
  setModalOpen,
  modalSize,
  back = false,
  prevStep,
}) => {
  return (
    <div>
      <input
        value={modalOpen}
        type="checkbox"
        checked={modalOpen}
        onChange={() => setModalOpen(!modalOpen)}
        className="modal-toggle"
      />
      <div className="modal w-full z-20">
        <div
          className={`p-0 relative modal-box  ${modalSize == "large" && "w-[80%] max-w-none h-full"
            } ${modalSize == "auto" && "lg:w-[45%] w-[90%] max-h-full max-w-none"
            } ${modalSize == "medium" && "lg:w-2/5 w-[90%] max-h-full max-w-none"
            }
          ${modalSize == "cgus" && "lg:w-3/5 w-[90%] max-h-[90%] max-w-none"
            }
           ${modalSize == "profile" && "lg:w-[65%] w-[90%] max-w-none"}`
          }
        >
          <label
            onClick={() => setModalOpen(!modalOpen)}
            className="absolute btn btn-sm btn-circle right-2 top-2 bg-[#D3D3D3]"
          >
            ✕
          </label>
          {back &&
            <label
              onClick={() => prevStep()}
              className="absolute btn btn-sm btn-circle left-2 top-2 bg-[#D3D3D3]"
            >
              {"<"}
            </label>
          }
          {title && (
            <div className="w-full flex justify-center bg-[#F7F7F8] py-4 items-center">

              {titleIcon && (titleIcon)}
              <h3 className="text-2xl font-bold">{title}</h3>
            </div>
          )}

          <div className="px-6 py-2">{children}</div>
        </div>
        <label
          className="modal-backdrop"
          onClick={() => setModalOpen(!modalOpen)}
        >
          Close
        </label>
      </div>
    </div>
  );
};

export default Modal;
