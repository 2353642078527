import React from "react";

/**
 * A chat message component that displays a message with a timestamp and an icon.
 *
 * @param {Object} props - The properties for the component.
 */

const Document = ({ document }) => {

  const { url, name, date_maj} = document

  return (
    <>
    <div className="article">
      - <a href={url}  target="_blank" rel="noreferrer">{name} {(date_maj !== "" && date_maj.trim()) && ("(dernière date de mise à jour : "+date_maj+")")}</a>
    </div>
    </>
          
    );
};

export default Document;
