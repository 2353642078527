import { MdOutlineNavigateNext } from "react-icons/md";
import { useEffect } from "react";
import React from "react";
import { useState, useRef } from "react";

const SolutionsCarrousel = ({ expert_id, solutions, handle_expert_click }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(null);

  const solutionRefs = useRef(solutions.map(() => React.createRef()));

  const checkForTruncation = () => {
    solutionRefs.current.forEach((ref, index) => {
      // Check if the current ref and its current DOM element exist
      if (ref.current && solutions[index]) {
        const container = ref.current;
        const text = container.querySelector(".price-text"); // Adjust the selector as needed
        // Check if the text element exists before accessing its properties
        if (text && container.offsetWidth < text.scrollWidth) {
          text.classList.add("truncate", "hover:whitespace-normal", "hover:text-left", "line-clamp-1", "hover:line-clamp-none");
        } else if (text) {
          text.classList.remove("truncate", "hover:whitespace-normal", "hover:text-left", "line-clamp-1", "hover:line-clamp-none");
        }
      }
    });
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (touchStartX.current === null) {
      return;
    }

    const touchEndX = e.touches[0].clientX;
    const deltaX = touchStartX.current - touchEndX;
    if (deltaX > 10) {
      // Swipe right, move to the next slide
      handleNext();
    } else if (deltaX < -10) {
      // Swipe left, move to the previous slide
      handlePrev();
    }

    touchStartX.current = null;
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === solutions.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? solutions.length - 1 : prevIndex - 1
    );
  };

  const indicatorClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [solutions]);

  useEffect(() => {
    checkForTruncation();
    // Add resize event listener
    window.addEventListener("resize", checkForTruncation);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkForTruncation);
    };
  }, [solutions, currentIndex]);

  return (
    <>
      {solutions.length > 0 ? (
        <div
          className="carousel-container relative w-full flex flex-col items-center"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div className="mt-2">
            {solutions.length > 1 && (
              <>
                <div className="indicators left-1/2 transform -translate-x-1/2 flex space-x-2 h-4 relative">
                  {solutions.map((_, index) => (
                    <div
                      onClick={() => indicatorClick(index)}
                      key={`s${index}`}
                      className={`cursor-pointer indicator h-2 w-2 rounded-full ${index === currentIndex ? "bg-blue-500" : "bg-gray-300"
                        }`}
                    ></div>
                  ))}
                </div>

                <button
                  className="absolute top-1 p-0 right-0  "
                  onClick={() => handleNext()}
                >
                  <MdOutlineNavigateNext className="w-4 h-4 text-black rounded-full bg-white" />
                </button>
              </>
            )}
          </div>

          {solutions.map((solution, index) => (
            <div
              key={`ss${index}`}
              className={`carousel-wrapper flex w-full bg-gray-700 rounded-md p-2 ${index === currentIndex ? "visible" : "hidden"
                }`}
            >
              <div className={`w-full text-white`}>
                <div className="flex flex-col w-[100%]">
                  <div className="flex">
                    <a
                      className={"truncate"}
                      href={solution.url}
                      onClick={() => handle_expert_click(expert_id)}
                      target="_blank"
                    >
                      <h3 className="text-blue-logo-color truncate font-bold text-sm ">
                        {solution.titre}
                      </h3>
                    </a>
                  </div>
                  <div className="flex justify-between w-full">
                    <p className="text-white text-[0.6rem] font-semibold w-1/2">
                      {solution.type}
                    </p>
                    <div className="w-1/2" ref={solutionRefs.current[index]}>
                      <p
                        className="text-[0.6rem] text-green-400 text-right cursor-default bg-gray-700 rounded  price-text whitespace-nowrap"
                        dangerouslySetInnerHTML={{ __html: solution.prix }}
                      ></p>
                    </div>
                  </div>
                  <p className="text-[0.65rem] w-[100%] truncate text-gray-400">
                    {solution.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}{" "}
    </>
  );
};

export default SolutionsCarrousel;
