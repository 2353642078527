import React, { useState, useRef, useEffect } from "react";
import ChatMessage from "./chat_message/ChatMessage";
import { MdSend } from "react-icons/md";
import { FaStop } from "react-icons/fa";
import { useAppContext } from "../../context/AppContext";
import VersionSwitch from "./VersionSwitch";

import VsersionsDisponibles from "../auth/VersionsDisponibles";
import Modal from "../Modal";
import logoInfogreffe from "../../assets/logoOliver_beta3.png";

import { db } from "../../firebase.config";
import { addDoc, collection, deleteDoc, getDocs, orderBy, query, serverTimestamp } from "firebase/firestore";
import { createMessage, update_last_system_message_tokens, updateMessage } from "../../utils/conversation";
/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = ({ open = false }) => {
  // Utilisation du contexte de l'application pour récupérer les états et les fonctions nécessaires.
  const {
    messages,
    experts,
    liensUtiles,
    writing,
    computingResponse,
    subdomain,
    setCurrentConversationId,

    clearChat,
    setWriting,
    currentUser,
    setMessages,
    sendMessage,
    handleCloseWS,
    customOliver,
    setSelectedOption,
  } = useAppContext(); // Accès au contexte global de l'application pour récupérer des données et fonctions.

  // Références pour le défilement automatique et la gestion du focus.
  const messagesEndRef = useRef();
  const inputRef = useRef();

  // État local pour le contenu du formulaire et les suggestions de messages.
  const [formValue, setFormValue] = useState("");
  const [suggestion, setSuggestion] = useState([]);

  // États pour gérer l'ouverture des versions et le titre du modal.
  const [versionsOpen, setVersionsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Versions disponibles");
  const [isModalOpen, setModalOpen] = useState(false);

  // Effet pour définir les suggestions de messages en fonction du contexte de l'application.
  useEffect(() => {
    setSuggestion(customOliver["suggestions_questions"] || []);
  }, [customOliver]);


  /** 
   * Gestionnaire pour envoyer des messages lorsque la touche "Entrée" est pressée.
   * La fonction vérifie quel'utilisateur n'est pas en train d'écrire,
   * que la valeur du formulaire n'est pas vide et qu'aucune réponse n'est en cours de calcul avant d'envoyer le message.
   */

  // Vérifier si la touche pressée est "Entrée", s'il n'y a pas d'écriture en cours, 
  // Vérifier si formValue a au moins 20 caractères
  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.type === "click") &&
      !writing && formValue.trim().length > 0 && !computingResponse) {
      if (formValue.trim().length < 20) {
        setModalOpen(true);
      } else {
        setSelectedOption({ displayOptionsFormalite: false });
        sendMessage(formValue, setFormValue);
      }
    }
  };

  // async function addNewConversation(userId, messages) {

  //   try {
  //     // Vérifier le nombre de conversations existantes
  //     const userConversationsRef = collection(db, "users", userId, "conversations");
  //     const conversationsQuery = query(userConversationsRef, orderBy("createdAt", "asc"));
  //     const querySnapshot = await getDocs(conversationsQuery);

  //     // Si le nombre de conversations est >= 20, supprimer la plus ancienne
  //     if (querySnapshot.size >= 20) {
  //       const oldestConversation = querySnapshot.docs[0];
  //       await deleteDoc(oldestConversation.ref);
  //       console.log("Oldest conversation deleted:", oldestConversation.id);
  //     }

  //     // Ajouter la nouvelle conversation
  //     const title = "";
  //     const newConversationRef = await addDoc(userConversationsRef, {
  //       titre: title,
  //       createdAt: serverTimestamp(),
  //       lastActive: serverTimestamp(),
  //       messages: messages
  //     });

  //     console.log("New conversation added with ID:", newConversationRef.id);
  //     return newConversationRef.id;
  //   } catch (error) {
  //     console.error("Error managing conversations:", error);
  //     throw error;
  //   }
  // }

  // const handleCreateConversation = async (userId, messages) => {
  //   try {
  //     console.log("handleCreateConversation: ", userId, messages);
  //     const conversationId = await addNewConversation(userId, messages);
  //     setCurrentConversationId(conversationId);
  //     console.log("Conversation créée avec succès. ID:", conversationId, "messages: ", messages);
  //   } catch (error) {
  //     console.error("Erreur lors de la création de la conversation:", error);
  //   }
  // }

  const handleSuggestions = async (message) => {
    const combinedMessage = `${message["primaire"]}\n${message["secondaire"]}`;
    setFormValue(combinedMessage);
    setSelectedOption({ displayOptionsFormalite: false });
    sendMessage(combinedMessage, setFormValue);
    // if (currentUser) {
    //   await handleCreateConversation(currentUser.uid, [createMessage(combinedMessage, "user", undefined, {}, -1)])
    // }
  };

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  useEffect(() => {
    const scroll = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    // Planifie le défilement pour après les mises à jour du DOM
    requestAnimationFrame(scroll);
  }, [messages, writing]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);




  return (
    // Gestion de la taille dynamique du conteneur de chat en fonction de l'ouverture et du contenu lié (liens utiles et experts).
    <div
      className={`flex flex-col  duration-300 relative w-full bg-slate-200 dark:bg-light-grey ${open ? "h-0" : `${liensUtiles.length > 0 || experts.length > 0 ? "h-[90%]" : "h-[100%]"}`} md:h-full`}
    >
      <main className="chatview__chatarea">
        {/* Affichage du switch de version du chat en haut de la zone de chat. */}
        <div className="w-full overflow-x-auto">
          <div className="w-full">
            {/* {subdomain !== "infogreffe" &&
              <VersionSwitch setVersionsOpen={setVersionsOpen} />} */}
          </div>
          {/* Container pour les messages avec une marge en bas via Tailwind */}
          <div className="mb-4 space-y-2">
            {/* Mapping et affichage de chaque message à l'aide du composant ChatMessage. */}
            {console.log(messages)}
            {messages.map((message, index) => {
              return (
                <ChatMessage
                  key={index}
                  index={index}
                  message={message}
                  writing={index === messages.length - 1 && writing}
                  setVersionsOpen={setVersionsOpen}
                />
              );
            })
            }
            {/* Cette condition vérifie s'il faut afficher un indicateur de saisie
        {writing && <div className="typing-indicator">Écriture en cours...</div>} */}
          </div>
          {/* Référence utilisée pour le défilement automatique vers le bas. */}

          <span ref={messagesEndRef}></span>
        </div>

        {/* Condition pour afficher les suggestions de messages lorsque le chat a un seul message. */}
        {messages.length === 1 &&
          <div className="md:mx-2 flex flex-wrap mb-2 relative">
            {suggestion.map((suggestedMessage, index) => (
              <div
                className={`md:w-1/2 w-full ${index > 1 && "md:block hidden"}`}
                key={index}
                onClick={() => handleSuggestions(suggestedMessage)}
              >
                <div className="m-1 border border-[#A0A0A0] rounded-md border-[0.1px] p-2 dark:hover:bg-light-white hover:bg-slate-300 cursor-pointer">
                  <p className="text-lg text-slate-700 dark:text-slate-200	leading-none mb-[6px]">
                    {suggestedMessage["primaire"]}
                  </p>
                  <p className="font-ligth text-[#A0A0A0] leading-none">
                    {suggestedMessage["secondaire"]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        }
      </main>

      {/* Formulaire pour envoyer de nouveaux messages. */}
      <form className="form mb-4 md:mb-1 flex flex-col">
        <div className="flex items-stretch justify-between w-full">
          <textarea
            ref={inputRef}
            readOnly={writing}
            autoFocus={true}
            className="chatview__textarea-message"
            value={formValue}
            placeholder="Posez une question claire en précisant le contexte... (20 caractères minimum)"
            onKeyDown={handleKeyDown}
            onChange={(e) => setFormValue(e.target.value)}
          />
          {writing ? (
            // Bouton pour arrêter l'envoi d'un message en cours.
            <button
              type="submit"
              className="chatview__btn-send"
              onClick={(e) => {
                e.preventDefault();
                handleCloseWS();
              }}
            >
              <FaStop size={30} className="text-gray-700" />
            </button>
          ) : (
            // Bouton pour envoyer un message.
            <button
              type="submit"
              className="chatview__btn-send"
              disabled={!formValue.trim() || computingResponse}
              onClick={(e) => {
                e.preventDefault();
                handleKeyDown(e);
                // sendMessage(formValue, setFormValue);
              }}
            >
              <MdSend size={30} />
            </button>
          )}
        </div>
      </form>

      {/* Modal pour les versions disponibles du chat, s'affiche en fonction de l'état versionsOpen*/}
      <Modal
        title={modalTitle}
        modalOpen={versionsOpen}
        setModalOpen={setVersionsOpen}
        modalSize={"auto"}
      >
        {/* Composant enfant qui affiche les versions disponibles et permet de changer la version active du chat. */}
        <VsersionsDisponibles setModalVersions={setVersionsOpen} modalVersion={versionsOpen} setModalTitle={setModalTitle} />
      </Modal>

      <Modal
        title="Erreur"
        modalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        modalSize={"auto"}
      >
        <div className="flex flex-col items-center justify-center p-4">
          <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" />
          <p className="text-center mb-6">Vous devez renseigner minimum <span className="underline">20 caractères</span> pour déclencher une réponse.</p>
          <p className="text-center mb-6">Donnez plus de contexte et/ou soyez plus précis.</p>
          <button
            onClick={() => setModalOpen(false)}
            className="signupFormContainerContinuer text-white font-bold"
          >
            OK, j'ai compris
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ChatView;
