import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import BientotDispo from "./pages/BientotDispo";
import RegistreFormalites from "./pages/RegistreFormalites"
import { ColorsContextProvider } from "./context/GlobalContext";
import { applyActionCode } from "firebase/auth";
import { useAppContext } from "./context/AppContext";
import ProtectedRoute from "./utils/ProtectedRoute";

const App = () => {
  const subdomain = window.location.hostname.split(".")[0];
  const { currentUser } = useAppContext();
  if (window.location.search.includes("mode=verifyEmail")) {
    const verifyEmail = async () => {
      try {
        const url = window.location.href;
        await applyActionCode(new URL(url).searchParams.get("oobCode"));
        alert("Email verified! You can now log in.");
      } catch (error) {
        alert(error.message);
      }
    };
    verifyEmail();
  }

  return (
    <ColorsContextProvider>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="auth/login" element={<Login />} />

          {subdomain === "infogreffe" && (<Route path="registre-formalites" element={
            <ProtectedRoute>
              <RegistreFormalites />
            </ProtectedRoute>
          } />)
          }

          <Route path="*" element={<BientotDispo />} />
        </Routes>
      </div>
    </ColorsContextProvider>
  );
};

export default App;
