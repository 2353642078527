import React, { useEffect, useRef, useState } from 'react';
import { IoTriangle, IoCaretDown } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useAppContext } from "../../context/AppContext";
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../firebase.config';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useWindowSize from '../../hooks/useWindowSize';
import { CiMenuKebab } from "react-icons/ci";
import { FiUpload } from "react-icons/fi";
import FileUploadComponent from '../user_profile/FileUploadComponent';
import Modal from '../Modal';
import { fetchData } from '../../utils/ask_api';


const RegistreFormalitiesTable = ({ formalities, setFormalities }) => {
    const { currentUser } = useAppContext();
    const [expandedRows, setExpandedRows] = React.useState({});
    const [titreDocument, setTitreDocument] = useState('')
    const [fileRecepisseUrl, setFileRecepisseUrl] = useState('')

    const { width } = useWindowSize();
    // Définir un seuil pour distinguer PC et smartphone
    const isMobile = width < 768;

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedFormality, setSelectedFormality] = useState(null);
    const [file, setFile] = useState(null);

    const toggleExpand = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getStatusClass = (status) => {
        switch (status) {
            case 'En attente de l’import des pièces et documents':
                return 'bg-yellow-200 text-yellow-600';
            case 'En cours d’enregistrement auprès du greffe':
                return 'bg-red-200 text-red-600';
            case 'Dossier enregistré auprès du greffe':
                return 'bg-green-200 text-green-600';
            default:
                return 'text-gray-500';
        }
    };

    const getStatusMessage = (status) => {
        switch (status) {
            case 'En attente de l’import des pièces et documents':
                return 'En cours';
            case 'En cours d’enregistrement auprès du greffe':
                return 'A traiter';
            case 'Dossier enregistré auprès du greffe':
                return 'Terminer';
            default:
                return 'text-gray-500';
        }
    };

    const [sortCriteria, setSortCriteria] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const sortFormalities = (formalities) => {
        return formalities.sort((a, b) => {
            if (sortCriteria === 'dateReservation') {
                const dateA = new Date(a.dateReservation.seconds * 1000);
                const dateB = new Date(b.dateReservation.seconds * 1000);
                return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortCriteria === 'status') {
                return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            }
            return 0;
        });
    };

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortDirection('asc');
        }
    };


    // const handleFileUploadSuccess = async (formalityId, docId, fileUrl, fileName, uploadDate) => {
    //     // docIndex = 5-titre
    //     // formalityId = WM8aNWkhwBha0UL3dONy2xpJkQs120240402072756
    //     // todo avant d'upload le ficher ajouter dans la collection 
    //     // formalites/WM8aNWkhwBha0UL3dONy2xpJkQs le doc
    //     // dans WM8aNWkhwBha0UL3dONy2xpJkQs120240402072756 : {..., required_documents : [{..},{..} ]}
    //     // required_documents.push( {titrePiece: titre, fileName: file.name, fileUrl: })

    //     setFormalities(currentFormalities => {
    //         return currentFormalities.map(formality => {
    //             if (formality.id === formalityId) {
    //                 const updatedDocuments = formality.required_documents.map(doc => {
    //                     if (doc.id === docId) {
    //                         return { ...doc, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
    //                     }
    //                     return doc;
    //                 });
    //                 return { ...formality, required_documents: updatedDocuments, upload_number_file: (formality.upload_number_file || 0) + 1 };
    //             }
    //             return formality;
    //         });
    //     });

    //     // Mettez à jour Firestore ici pour incrémenter le compteur et stocker l'URL du fichier
    //     const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
    //     try {
    //         const docSnap = await getDoc(userFormalitiesRef);

    //         if (docSnap.exists()) {
    //             let userData = docSnap.data();

    //             let updatedFormality = userData[formalityId];
    //             let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
    //                 if (index === docId) {
    //                     return { ...document, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
    //                 }
    //                 return document;
    //             });

    //             updatedFormality.required_documents = updatedDocuments;
    //             updatedFormality.upload_number_file = (updatedFormality.upload_number_file || 0) + 1;

    //             // Mise à jour de Firestore avec la formalité mise à jour
    //             await updateDoc(userFormalitiesRef, {
    //                 [formalityId]: updatedFormality
    //             });

    //             console.log("Document mis à jour avec succès");
    //         }
    //     } catch (error) {
    //         console.error("Erreur lors de la mise à jour du document:", error);
    //     }
    // };

    const handleFileUploadSuccess = async (formalityId, docId, fileUrl, fileName, uploadDate) => {
        // Mise à jour locale de l'état des formalités
        setFormalities(currentFormalities => {
            return currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    // Vérifie si le document existe déjà, sinon l'ajoute
                    let updatedDocuments = [...formality.required_documents];
                    let found = false;
                    updatedDocuments = updatedDocuments.map(doc => {
                        if (doc.id === docId) {
                            found = true;
                            return { ...doc, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                        }
                        return doc;
                    });

                    if (!found) {
                        updatedDocuments.push({
                            id: docId,
                            titrePiece: docId.split('-')[1], // Utilisation de split pour extraire le titre à partir de docId
                            fileName: fileName,
                            fileUrl: fileUrl,
                            uploaded: true,
                            uploadDate: uploadDate
                        });
                    }

                    return { ...formality, required_documents: updatedDocuments, upload_number_file: (formality.upload_number_file || 0) + 1 };
                }
                return formality;
            });
        });

        // Référence Firestore de la formalité de l'utilisateur
        const uid = formalityId.replace(/\d{14}$/, '');
        const userFormalityRef = doc(db, `formalities/${uid}`);
        try {
            // Récupérer la formalité existante
            const docSnap = await getDoc(userFormalityRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let formalityData = userData[formalityId];

                // Mise à jour ou ajout de nouveaux documents requis
                let updatedDocuments = [...formalityData.required_documents];
                let found = false;
                updatedDocuments = updatedDocuments.map(document => {
                    console.log("document: ", document);
                    if (document.id === docId) {
                        found = true;
                        return { ...document, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                    }
                    return document;
                });

                if (!found) {
                    updatedDocuments.push({
                        id: docId,
                        titrePiece: docId.split('-')[1],
                        fileName: fileName,
                        fileUrl: fileUrl,
                        uploaded: true,
                        uploadDate: uploadDate
                    });
                }

                formalityData.required_documents = updatedDocuments;
                formalityData.upload_number_file = (formalityData.upload_number_file || 0) + 1;

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalityRef, { [formalityId]: formalityData });

                console.log("Formalité mise à jour avec succès dans Firestore");
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la formalité dans Firestore:", error);
        }
    };


    const handleFileUploadRemove = async (formalityId, docId) => {
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        fileUrlToRemove = document.fileUrl;
                        return { ...document, fileUrl: null, uploaded: false, fileName: "", uploadDate: null };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;
                updatedFormality.upload_number_file = Math.max(0, (updatedFormality.upload_number_file || 0) - 1);

                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                if (fileUrlToRemove) {
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, required_documents: updatedDocuments, upload_number_file: updatedFormality.upload_number_file };
                        }
                        return formality;
                    });
                });

            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };

    const isAllDocumentsRequisUploaded = (formality) => {
        if (!formality.upload_number_file || formality.upload_number_file === 0) {
            return false;
        }
        return formality.required_documents.every(doc => {
            return !doc.required || (doc.required && doc.fileUrl);
        });
    };

    // Fonction appelée lorsque l'utilisateur clique sur "Envoyer"
    const handleSendFormality = async (formalityId) => {
        // Référence au document de l'utilisateur dans Firestore
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            // Récupération du document de l'utilisateur
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                // Accès aux données de l'utilisateur
                let userData = docSnap.data();

                // Accès à la formalité spécifique à mettre à jour
                let updatedFormality = userData[formalityId];

                // Mise à jour du statut et de la date d'envoi de la formalité
                updatedFormality.status = "En cours d’enregistrement auprès du greffe";
                updatedFormality.sendDate = new Date(); // Utiliser Timestamp de Firestore si nécessaire

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                console.log("Formalité envoyée avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la formalité:", error);
        }
    };

    const displayFormattedDate = (timestamp) => {
        // Si timestamp existe, convertissez-le en objet Date et formatez
        if (timestamp) {
            const date = timestamp.toDate();
            return `Envoyé le ${date.toLocaleDateString('fr-FR')}`;
        }
        // Gestion des cas où timestamp n'est pas disponible
        return 'Date non disponible';
    };

    const handleFileChange = (e, formalityId, docIndex) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        uploadFile(file, formalityId, docIndex);
    };

    const uploadFile = (file, formalityId, docIndex) => {

        console.log(`formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const storageRef = ref(storage, `formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    // setFileUrl(downloadURL);
                    // setUploading(false);
                    // onUploadSuccess(downloadURL, file.name, date);
                    console.log("formalityId: ", formalityId);
                    console.log("docIndex: ", docIndex);
                    console.log("downloadURL: ", downloadURL);
                    console.log("file.name: ", file.name);
                    console.log("date: ", date);
                    handleFileUploadSuccess(formalityId, docIndex, downloadURL, file.name, date)

                });
            }
        );
    };

    const isValidFile = (file) => {
        // La taille maximale autorisée en octets (20Mo = 20 * 1024 * 1024 octets)
        const maxSize = 20 * 1024 * 1024;
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        return file.size <= maxSize && allowedTypes.includes(file.type);
    };

    const handleFileRecepisse = (e) => {
        const file = e.target.files[0];
        setFile(file);
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        // selectedFormality
        console.log(`formalities/${selectedFormality.id}/recepisse/${file.name}`);
        const storageRef = ref(storage, `formalities/${selectedFormality.id}/recepisse/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    setFileRecepisseUrl(downloadURL);
                    // // setUploading(false);
                    // // onUploadSuccess(downloadURL, file.name, date);
                    // console.log("formalityId: ", formalityId);
                    // console.log("docIndex: ", docIndex);
                    console.log("downloadURL: ", downloadURL);
                    console.log("file.name: ", file.name);
                    console.log("date: ", date);

                });
            }
        );


    };

    // const scrollableTableRef = useRef(null);

    // useEffect(() => {
    //     if (formalities.length > 0 && scrollableTableRef.current) {
    //         scrollableTableRef.current.scrollLeft = scrollableTableRef.current.scrollWidth;
    //     }
    // }, [formalities]);

    // Initialiser un état pour stocker les titres de tous les documents
    const [documentTitles, setDocumentTitles] = useState({});

    const handleTitleChange = (id, value) => {
        // Mettre à jour le titre spécifique pour la formalité par son ID
        setDocumentTitles(prev => ({ ...prev, [id]: value }));
    };

    const [visibleMenu, setVisibleMenu] = useState(null);

    const toggleMenu = (formalityId) => {
        setVisibleMenu(visibleMenu === formalityId ? null : formalityId); // Toggle le menu visible
    };

    const handleCloseMenu = () => {
        setVisibleMenu(null); // Ferme le menu
    };

    const handleClosureSelected = (formalityId) => {
        console.log("Clôturer la formalité:", formalityId);
        handleOpenModal(formalityId);
        handleCloseMenu(); // Ferme le menu après la sélection
    };

    const messageToUser = (formality) => {
        return `Bonjour ${formality.prenom} ${formality.nom},

Nous sommes ravis de vous informer que la formalité concernant ${formality.titreFormalitie} a été complétée avec succès. 

Votre récépissé du greffe a été correctement traité et est désormais disponible. Vous pouvez consulter et télécharger le document officiel directement depuis votre espace client et le retrouver également en pièce jointe à ce message.

Nous vous remercions de votre confiance et restons à votre disposition pour toute question supplémentaire ou pour toute nouvelle demande.

Cordialement,
L'Équipe Oliver`
    }
    const [message, setMessage] = useState("Message par défaut à transmettre au client");

    const handleOpenModal = (formality) => {
        setSelectedFormality(formality);
        setMessage(messageToUser(formality));
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedFormality(null);
        setFile(null);
        setFileRecepisseUrl(null);
    };


    const updateFormalityStatus = async (formality, newStatus) => {
        const uid = formality.id.replace(/\d{14}$/, '');
        const formalityRef = doc(db, `formalities/${uid}`);

        try {
            // Récupérer la formalité existante
            const docSnap = await getDoc(formalityRef);

            if (docSnap.exists()) {
                // Mise à jour du statut de la formalité
                let userData = docSnap.data();
                let formalityData = userData[formality.id];

                formalityData.status = newStatus;
                formalityData["urlRecepisse"] = fileRecepisseUrl;
                await updateDoc(formalityRef, { [formality.id]: formalityData });
                console.log("Le statut de la formalité a été mis à jour avec succès.");
            } else {
                console.error("La formalité spécifiée n'existe pas dans Firestore.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut de la formalité dans Firestore:", error);
        }
    };



    const handleFormSubmit = (event) => {
        event.preventDefault();
        console.log("Formalité terminée avec:", {
            file,
            message,
            formality: selectedFormality
        });
        const payload = {
            email_contact: selectedFormality.email_contact,
            message: message,
            file: file,
        };
        fetchData('formality-completed', payload, 'POST', true)
            .then(data => {
                console.log('Success:', data);
                updateFormalityStatus(selectedFormality, "Dossier enregistré auprès du greffe")
            })
            .catch((error) => {
                console.error('Error:', error);
            });


        handleCloseModal();
    };

    if (isMobile && false) {
        // Rendu pour smartphones
        return (
            <div className="mt-6">
                {formalities.map((formality, index) => (
                    <div key={`formality-${index}`} className="mb-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="col-span-2 md:col-span-1 font-bold">Date de demande:</div>
                            <div className="col-span-2 md:col-span-1">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}</div>
                            <div className="col-span-2 font-bold">Formalité:</div>
                            <div className="col-span-2">{formality.categorie} {">"} {formality.titreFormalitie}</div>
                            <div className="col-span-2 font-bold">Dénomination:</div>
                            <div className="col-span-2">{formality.nomEntreprise}</div>
                            <div className="col-span-2 font-bold">Montant HT:</div>
                            <div className="col-span-2">{formality.montantHT} €</div>
                            <div className="col-span-2 font-bold">Contact :</div>
                            <div className="col-span-2">
                                {formality.prenom} {formality.nom}<br />
                                {formality.email_contact}
                            </div>

                            <div className="col-span-2 font-bold">Facture :</div>
                            <div className="col-span-2">
                                <a href={formality.invoice_pdf_url} className="text-blue-600 hover:text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>
                            </div>

                            <div className="col-span-2 font-bold">Statut :</div>
                            <div className={`col-span-2 ${getStatusClass(formality.status)}`}>
                                {formality.status}
                            </div>

                            {formality.sendDate && (
                                <span className="text-blue-logo-color"> {displayFormattedDate(formality.sendDate)}</span>
                            )}

                            <button onClick={() => toggleExpand(formality.id)} className="col-span-2 flex justify-between items-center text-blue-logo-color">
                                <span>Documents & pièces requis ({formality?.upload_number_file || 0} / {formality.required_documents.length})</span>
                                {expandedRows[formality.id] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </button>

                            {/* Contenu dépliable */}
                            {expandedRows[formality.id] && (
                                <div className="col-span-2">
                                    <div className="mt-4">
                                        {formality.required_documents.map((doc, docIndex) => (
                                            <div key={docIndex} className="mb-8">
                                                <div className="mb-2 flex flex-col items-start">
                                                    <label htmlFor={`file-input-${docIndex}`} className="block text-sm bg-white font-bold text-blue-logo-color dark:text-white mb-1">
                                                        {doc.titrePiece}
                                                        {doc.required && <span className="text-red-500">*</span>}
                                                        {doc.urlModel && (
                                                            <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
                                                                <BsDownload className='w-5 h-5 mr-2' />
                                                                Télécharger modèle
                                                            </a>
                                                        )}
                                                    </label>
                                                </div>
                                                <FileUploadComponent
                                                    docIndex={docIndex + '-' + doc.titrePiece}
                                                    formalityId={formality.id}
                                                    existingFileUrl={doc.fileUrl}
                                                    existingFileName={doc.fileName}
                                                    existingUploadDate={doc.uploadDate}
                                                    isSent={!!formality.sendDate}
                                                    onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                        console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                        handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                    }}
                                                    onUploadRemove={() => {
                                                        console.log(`Fichier a été supprimé avec succès`);
                                                        handleFileUploadRemove(formality.id, docIndex)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex justify-center space-x-2">
                                        {!formality.sendDate && (
                                            <>
                                                <button
                                                    className={`p-2 rounded-lg px-8 bg-transparent text-blue-logo-color border-2 ${isAllDocumentsRequisUploaded(formality) ? 'border-blue-logo-color hover:bg-blue-logo-color hover:text-white' : 'border-gray-300 text-gray-300 cursor-not-allowed'}`}
                                                    disabled={!isAllDocumentsRequisUploaded(formality)}
                                                    onClick={() => handleSendFormality(formality.id)}
                                                >
                                                    Envoyer
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        // Rendu pour PC
        return (
            <div className="overflow-x-auto p-6 bg-gray-300 ">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg overflow-hidden bg-white">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3  w-full whitespace-nowrap">
                                Date de demande
                                <button onClick={() => handleSort('dateReservation')}>🔽</button>
                            </th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Formalité</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Dénomination</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">N°Siret</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Montant HT</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Contact</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Facture</th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">
                                Etat
                                <button onClick={() => handleSort('status')}>🔽</button>
                            </th>
                            <th scope="col" className="px-6 py-3 w-full whitespace-nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {sortFormalities(formalities).map((formality, index) => (
                                <React.Fragment key={`fragment-${formality.dateReservation}-${index}`}>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString("fr-FR", {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: false
                                        })}</td>
                                        <td className="px-6 py-4">{Capitalize(formality.categorie)} {">"} {Capitalize(formality.titreFormalitie)}</td>
                                        <td className="px-6 py-4">{formality.nomEntreprise}</td>
                                        <td className="px-6 py-4">{formality.siret}</td>
                                        <td className="px-6 py-4">{formality.montantHT} €</td>
                                        <td className="px-6 py-4">{formality.prenom} {formality.nom}<br />{formality.email_contact} <br /> {formality.telephone}</td>
                                        <td className="px-6 py-4">
                                            {(<a href={formality.invoice_pdf_url} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>)}
                                        </td>
                                        <td className={`px-6 py-4`}> <div className={`border font-bold text-center ${getStatusClass(formality.status)}`}>{getStatusMessage(formality.status)}</div></td>
                                        <td className="px-6 py-4 relative">
                                            <button onClick={() => toggleMenu(formality.id)}>
                                                <CiMenuKebab size={25} />
                                            </button>
                                            {visibleMenu === formality.id && getStatusMessage(formality.status) === "A traiter" && (
                                                <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
                                                    <ul className="text-gray-700">
                                                        <li>
                                                            <button
                                                                onClick={() => handleClosureSelected(formality)}
                                                                className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                            >
                                                                Clôturer la formalité
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    {(<tr onClick={() => toggleExpand(formality.id)} className="bg-sky-100 cursor-pointer border-b dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                                        <td className="px-6 text-center" colSpan="7">
                                            <div className='text-blue-logo-color'>
                                                Documents & pièces requis : ({formality?.upload_number_file || 0} / {formality.required_documents.length})
                                            </div>
                                        </td>
                                        <td className="px-6 text-center" colSpan="2">
                                            <div className="flex items-center justify-center space-x-1">
                                                <div className="flex items-center justify-center space-x-1">
                                                    {formality.sendDate ? (
                                                        <span className="text-blue-logo-color w-full whitespace-nowrap"> {displayFormattedDate(formality.sendDate)}</span>
                                                    )
                                                        : (
                                                            <span className="text-blue-logo-color w-full whitespace-nowrap"> En attente</span>

                                                        )
                                                    }
                                                    <button
                                                        className="flex items-center justify-center"
                                                    >
                                                        {expandedRows[formality.id] ? <IoTriangle className='text-blue-logo-color' /> : <IoCaretDown className='text-blue-logo-color w-5 h-5' />}
                                                    </button>
                                                </div>

                                            </div>
                                        </td>

                                    </tr>)}
                                    {
                                        expandedRows[formality.id] && (
                                            <tr className='bg-sky-100 border-b'>
                                                <td colSpan="9">
                                                    <div className='py-4 w-full max-w-4xl mx-auto'>
                                                        {formality.required_documents.map((doc, docIndex) => (
                                                            <div key={docIndex} className="mb-8">
                                                                <div className="mb-2 flex flex-col items-start">
                                                                    <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                                        {doc.titrePiece}
                                                                        {doc.required && <span className="text-red-500">*</span>}
                                                                        {/* {doc.urlModel && (
                                                                            <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
                                                                                <BsDownload className='w-5 h-5 mr-2' />
                                                                                Télécharger modèle
                                                                            </a>
                                                                        )} */}
                                                                    </label>
                                                                </div>
                                                                <FileUploadComponent
                                                                    docIndex={docIndex + '-' + doc.titrePiece}
                                                                    formalityId={formality.id}
                                                                    existingFileUrl={doc.fileUrl}
                                                                    existingFileName={doc.fileName}
                                                                    existingUploadDate={doc.uploadDate}
                                                                    isSent={false}
                                                                    docId={doc?.id}
                                                                    registerFormalaties={true}
                                                                    onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                                        console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                                        handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                                    }}
                                                                    onUploadRemove={() => {
                                                                        console.log(`Fichier a été supprimé avec succès`);
                                                                        handleFileUploadRemove(formality.id, docIndex)
                                                                    }}
                                                                />




                                                            </div>
                                                        ))}

                                                        <label className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                            Nouveau document
                                                        </label>
                                                        <div key={formality.id} className='flex w-full items-center'>
                                                            <input
                                                                type='text'
                                                                style={{ flex: '3' }}
                                                                value={documentTitles[formality.id] || ''} // Utiliser l'ID de la formalité pour différencier les états
                                                                onChange={(e) => handleTitleChange(formality.id, e.target.value)}
                                                                placeholder='Titre du document...'
                                                                className='bg-white border-slate-400 border-2 rounded-lg p-3 text-black flex-grow mr-2'
                                                                id={`inputTitreDocument-${formality.id}`}
                                                            />
                                                            <label
                                                                className="bg-blue-400 text-white rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-center hover:text-gray-500 flex-shrink-0 p-3"
                                                                style={{ flex: '1' }}>
                                                                <span className="text-base leading-normal">Chercher un fichier</span>
                                                                <input
                                                                    type="file"
                                                                    className="hidden"
                                                                    disabled={!documentTitles[formality.id]}
                                                                    onChange={(e) => {
                                                                        const titrePiece = documentTitles[formality.id];
                                                                        handleFileChange(e, formality.id, `${formality.required_documents.length}-${titrePiece}`);
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>


                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }





                                </React.Fragment>
                            ))
                            }
                        </>
                    </tbody >
                </table >
                <Modal
                    title="Clôturer la formalité"
                    modalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    modalSize={"profile"}
                >
                    <div className="flex flex-col items-center justify-center p-4">
                        <textarea
                            className="w-full h-64 p-1 mr-2 input input-bordered border-black mb-3"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        {/* <input type="file" onChange={handleFileRecepisse} required /> */}
                        <label
                            className="bg-blue-400 text-white mb-1 rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-center hover:text-gray-500 flex-shrink-0 p-3 flex items-center justify-center"
                            style={{ flex: '1' }}>

                            <span className="text-base leading-normal">Chercher le Récépissé du greffe</span>
                            <FiUpload className="text-center ml-2" size={20} />
                            <input
                                type="file"
                                className="hidden"
                                // disabled={!documentTitles[formality.id]}
                                onChange={(e) => {
                                    handleFileRecepisse(e);
                                    // const titrePiece = documentTitles[formality.id];
                                    // handleFileChange(e, formality.id, `${formality.required_documents.length}-${titrePiece}`);
                                }}
                            />
                        </label>
                        {fileRecepisseUrl && (
                            <div className="my-3 flex justify-start">
                                <a href={fileRecepisseUrl} className="text-blue-600 hover:text-blue-800 hover:underline mr-4" target="_blank" rel="noopener noreferrer">Télécharger</a>
                                <button
                                    onClick={() => {
                                        const fileRef = ref(storage, fileRecepisseUrl);
                                        deleteObject(fileRef).then(() => {
                                            console.log("File deleted successfully");
                                            setFile(null);
                                            setFileRecepisseUrl('');
                                        }).catch((error) => {
                                            console.log(error);
                                        });
                                    }}
                                    className="hover:underline hover:text-red-500 "
                                >
                                    Supprimer
                                </button>
                            </div>
                        )}
                        <button type="submit"
                            className="text-white mb-3 rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-center hover:text-gray-500 flex-shrink-0 p-3 flex items-center justify-cente bg-green-500"
                            disabled={!file}
                            onClick={handleFormSubmit}

                        >Formalité terminée</button>

                    </div>
                </Modal>
            </div >
        );
    }
};

export default RegistreFormalitiesTable;
