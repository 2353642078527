
export function getEnvConfig() {
    const hostname = window.location.hostname;
    if (hostname.includes('staging')) {
        return {
            apiUrl: 'https://staging.oliver.legal/',
            wsUrl: 'wss:/staging.oliver.legal/ws'
        };
    } else if (hostname.includes('localhost')) {
        return {
            apiUrl: 'http://localhost:8000/',
            wsUrl: 'ws:/localhost:8000/ws'
        };
    }
    else {
        return {
            apiUrl: 'https://oliver.legal/',
            wsUrl: 'wss:/oliver.legal/ws'
        };
    }
}
