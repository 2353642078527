import React from "react";
import LoginFormInfogreffe from "./LoginFormInfogreffe";
import logo from "../../assets/oliverLogo.png";
import logoInfogreffe from "../../assets/oliverLogo_infogreffe.png";
import Cgus from "./Cgus";
import { useColorsContext } from "../../context/GlobalContext";
import { useState } from "react";

const Login = ({ message = null, setModalLoginOpen }) => {
  const { subdomain } = useColorsContext();
  const [action, setAction] = useState("");
  const closeModal = () => {
    setModalLoginOpen(false);
  };
  const [cgus, setCgus] = useState("");
  return (
    <div className="h-full">
      <div className={`flex flex-col items-center justify-center space-y-10 `}>
        <img className={"w-52"} src={`${subdomain === "infogreffe" ? logoInfogreffe : logo}`} alt="logo mini" />
        {/* <img className={"w-52"} src={`${subdomain === "infogreffe" ? logoInfogreffe : logo}`} alt="logo mini" /> */}
        {message === "need_to_be_connected" ? (
          <p className="text-center font-bold text-gray-400">
            <>
              Pour tester la version avancée d'Oliver <br /> vous devez être
              connecté.
            </>
          </p>
        ) : message === "need_to_be_connected_for_reservation" ? (
          <p className="text-center font-bold text-gray-400">
            Pour réserver une formalité, vous devez être connecté(e).
          </p>
        ) : (
          <>
            {action === "" &&
              (
                <p className="p-0 text-center font-bold text-gray-400 ">
                  <>Pour continuer, veuillez vous connecter ou vous inscrire.</>
                </p>
              )}
          </>
        )}
        <div className={`pb-8 w-full ${cgus == "" && "hidden"}`}>
          <div className="md:px-8">
            <Cgus setCgus={setCgus} content={cgus} signup={true} />
          </div>
        </div>
        <div className={`pb-8 w-full ${cgus != "" && "hidden"}`}>
          <div className="md:px-24">
            <LoginFormInfogreffe
              setCgus={setCgus}
              closeModal={closeModal}
              action={action}
              setAction={setAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
