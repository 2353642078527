import SideBar from "../components/SideBar";
import ChatView from "../components/chat_view/ChatView";
import RightPanel from "../components/right_panel/RightPanel";
import { useState } from "react";

const Home = () => {
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  return (
    <div className="flex transition duration-500 ease-in-out w-[100%] h-[100dvh]">
      <div className="flex w-[100vw] md:w-[75%] relative">
        <SideBar />

        <div className="flex flex-col h-full duration-300 w-max overflow-hidden">
          <ChatView open={rightPanelOpen} />
          <RightPanel
            mobileMode={true}
            open={rightPanelOpen}
            setOpen={setRightPanelOpen}
          />
        </div>
      </div>

      <RightPanel />
    </div>
  );
};

export default Home;
