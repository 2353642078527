import ViewInvoices from "./viewInvoices";
import ViewUsage from "./ViewUsage";
import React from "react";
import CreditAccount from "./CreditAccount";
import { useState } from "react";
import { useAppContext } from "../../context/AppContext";

const UserProfile = ({ setOpen }) => {
  const ViewUsageMemo = React.memo(ViewUsage);
  const ViewInvoicesMemo = React.memo(ViewInvoices);
  const CreditAccountMemo = React.memo(CreditAccount);
  const { credits, creditUnitPrice } = useAppContext();
  const [creditOpen, setCreditOpen] = useState(false);
  return (
    <>
      <div className="w-full md:px-20">
        <div className={`text-[10px] text-orange-logo-color mb-2 ${creditOpen && "hidden"}`}>
          Crédits restants : {credits} {"("+(creditUnitPrice*credits).toFixed(2)+"€)"}
        </div>
        <button
          onClick={() => {
            setCreditOpen(!creditOpen);
          }}
          className="text-[12px] font-semibold text-orange-logo-color border border-orange-logo-color p-[0.5em] rounded hover:bg-orange-logo-color hover:text-white"
        >
          {creditOpen ? "Retour" : "Alimenter mon compte"}
        </button>
        {creditOpen ? (
          <div className={`md:px-32 ${creditOpen ? "block" : "hidden"}`}>
            <CreditAccountMemo setOpen={setCreditOpen} />
          </div>
        ) : (
          <div className="space-y-10">
            <div>
              <ViewUsageMemo />
            </div>

            <div className="overflow-y-scroll md:px-12">
              <ViewInvoicesMemo />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
