import React, { useEffect, useRef, useState } from 'react';
import { IoTriangle, IoCaretDown } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
import FileUploadComponent from './FileUploadComponent';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useAppContext } from "../../context/AppContext";
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../firebase.config';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useWindowSize from '../../hooks/useWindowSize';

const FormalitiesTable = ({ formalities, setFormalities }) => {
    const { currentUser } = useAppContext();
    const [expandedRows, setExpandedRows] = React.useState({});

    const { width } = useWindowSize();
    // Définir un seuil pour distinguer PC et smartphone
    const isMobile = width < 768;

    const toggleExpand = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'En attente de l’import des pièces et documents':
                return 'text-orange-500';
            case 'En cours d’enregistrement auprès du greffe':
                return 'text-purple-500';
            case 'Dossier enregistré auprès du greffe':
                return 'text-green-500';
            default:
                return 'text-gray-500';
        }
    };

    const [sortCriteria, setSortCriteria] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const sortFormalities = (formalities) => {
        return formalities.sort((a, b) => {
            if (sortCriteria === 'dateReservation') {
                const dateA = new Date(a.dateReservation.seconds * 1000);
                const dateB = new Date(b.dateReservation.seconds * 1000);
                return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortCriteria === 'status') {
                return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            }
            return 0;
        });
    };

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortDirection('asc');
        }
    };


    const handleFileUploadSuccess = async (formalityId, docId, fileUrl, fileName, uploadDate) => {
        setFormalities(currentFormalities => {
            return currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    const updatedDocuments = formality.required_documents.map(doc => {
                        if (doc.id === docId) {
                            return { ...doc, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                        }
                        return doc;
                    });
                    return { ...formality, required_documents: updatedDocuments, upload_number_file: (formality.upload_number_file || 0) + 1 };
                }
                return formality;
            });
        });

        // Mettez à jour Firestore ici pour incrémenter le compteur et stocker l'URL du fichier
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();

                let updatedFormality = userData[formalityId];
                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        return { ...document, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;
                updatedFormality.upload_number_file = (updatedFormality.upload_number_file || 0) + 1;

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                console.log("Document mis à jour avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du document:", error);
        }
    };

    const handleFileUploadRemove = async (formalityId, docId) => {
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        fileUrlToRemove = document.fileUrl;
                        return { ...document, fileUrl: null, uploaded: false, fileName: "", uploadDate: null };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;
                updatedFormality.upload_number_file = Math.max(0, (updatedFormality.upload_number_file || 0) - 1);

                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                if (fileUrlToRemove) {
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, required_documents: updatedDocuments, upload_number_file: updatedFormality.upload_number_file };
                        }
                        return formality;
                    });
                });

            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };

    const isAllDocumentsRequisUploaded = (formality) => {
        if (!formality.upload_number_file || formality.upload_number_file === 0) {
            return false;
        }
        return formality.required_documents.every(doc => {
            return !doc.required || (doc.required && doc.fileUrl);
        });
    };

    // Fonction appelée lorsque l'utilisateur clique sur "Envoyer"
    const handleSendFormality = async (formalityId) => {
        // Référence au document de l'utilisateur dans Firestore
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            // Récupération du document de l'utilisateur
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                // Accès aux données de l'utilisateur
                let userData = docSnap.data();

                // Accès à la formalité spécifique à mettre à jour
                let updatedFormality = userData[formalityId];

                // Mise à jour du statut et de la date d'envoi de la formalité
                updatedFormality.status = "En cours d’enregistrement auprès du greffe";
                updatedFormality.sendDate = new Date(); // Utiliser Timestamp de Firestore si nécessaire

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                console.log("Formalité envoyée avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la formalité:", error);
        }
    };

    const displayFormattedDate = (timestamp) => {
        // Si timestamp existe, convertissez-le en objet Date et formatez
        if (timestamp) {
            const date = timestamp.toDate();
            return `Envoyé le ${date.toLocaleDateString('fr-FR')}`;
        }
        // Gestion des cas où timestamp n'est pas disponible
        return 'Date non disponible';
    };

    // Initialiser un état pour stocker les titres de tous les documents
    const [documentTitles, setDocumentTitles] = useState({});

    const handleTitleChange = (id, value) => {
        // Mettre à jour le titre spécifique pour la formalité par son ID
        setDocumentTitles(prev => ({ ...prev, [id]: value }));
    };

    const isValidFile = (file) => {
        // La taille maximale autorisée en octets (20Mo = 20 * 1024 * 1024 octets)
        const maxSize = 20 * 1024 * 1024;
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        return file.size <= maxSize && allowedTypes.includes(file.type);
    };

    const handleFileChange = (e, formalityId, docIndex) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        uploadFile(file, formalityId, docIndex);
    };

    const uploadFile = (file, formalityId, docIndex) => {

        console.log(`formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const storageRef = ref(storage, `formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    // setFileUrl(downloadURL);
                    // setUploading(false);
                    // onUploadSuccess(downloadURL, file.name, date);
                    console.log("formalityId: ", formalityId);
                    console.log("docIndex: ", docIndex);
                    console.log("downloadURL: ", downloadURL);
                    console.log("file.name: ", file.name);
                    console.log("date: ", date);
                    handleFileUploadSuccess(formalityId, docIndex, downloadURL, file.name, date)

                });
            }
        );
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const scrollableTableRef = useRef(null);

    useEffect(() => {
        if (formalities.length > 0 && scrollableTableRef.current) {
            scrollableTableRef.current.scrollLeft = scrollableTableRef.current.scrollWidth;
        }
    }, [formalities]);

    if (isMobile) {
        // Rendu pour smartphones
        return (
            <div className="mt-6">
                {formalities.map((formality, index) => (
                    <div key={`formality-${index}`} className="mb-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="col-span-2 md:col-span-1 font-bold">Date de demande:</div>
                            <div className="col-span-2 md:col-span-1">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}</div>
                            <div className="col-span-2 font-bold">Formalité:</div>
                            <div className="col-span-2">{formality.categorie} {">"} {formality.titreFormalitie}</div>
                            <div className="col-span-2 font-bold">Dénomination:</div>
                            <div className="col-span-2">{formality.nomEntreprise}</div>
                            <div className="col-span-2 font-bold">Montant HT:</div>
                            <div className="col-span-2">{formality.montantHT} €</div>
                            <div className="col-span-2 font-bold">Contact :</div>
                            <div className="col-span-2">
                                {formality.prenom} {formality.nom}<br />
                                {formality.email_contact}
                            </div>

                            <div className="col-span-2 font-bold">Facture :</div>
                            <div className="col-span-2">
                                <a href={formality.invoice_pdf_url} className="text-blue-600 hover:text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>
                            </div>

                            <div className="col-span-2 font-bold">Statut :</div>
                            <div className={`col-span-2 ${getStatusClass(formality.status)}`}>
                                {formality.status}
                                {formality?.urlRecepisse &&
                                    <a href={formality.urlRecepisse} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger le récépissé</a>

                                }
                            </div>

                            {formality.sendDate && (
                                <span className="text-blue-logo-color"> {displayFormattedDate(formality.sendDate)}</span>
                            )}

                            <button onClick={() => toggleExpand(formality.id)} className="col-span-2 flex justify-between items-center text-blue-logo-color">
                                <span>Documents & pièces requis ({formality?.upload_number_file || 0} / {formality.required_documents.length})</span>
                                {expandedRows[formality.id] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </button>

                            {/* Contenu dépliable */}
                            {expandedRows[formality.id] && (
                                <div className="col-span-2">
                                    <div className="mt-4">
                                        {formality.required_documents.map((doc, docIndex) => (
                                            <div key={docIndex} className="mb-8">
                                                <div className="mb-2 flex flex-col items-start">
                                                    <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                        {doc.titrePiece}
                                                        {doc.required && <span className="text-red-500">*</span>}
                                                        {doc.urlModel && (
                                                            <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
                                                                <BsDownload className='w-5 h-5 mr-2' />
                                                                Télécharger modèle
                                                            </a>
                                                        )}
                                                    </label>
                                                </div>
                                                <FileUploadComponent
                                                    docIndex={docIndex + '-' + doc.titrePiece}
                                                    formalityId={formality.id}
                                                    existingFileUrl={doc.fileUrl}
                                                    existingFileName={doc.fileName}
                                                    existingUploadDate={doc.uploadDate}
                                                    isSent={!!formality.sendDate}
                                                    onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                        console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                        handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                    }}
                                                    onUploadRemove={() => {
                                                        console.log(`Fichier a été supprimé avec succès`);
                                                        handleFileUploadRemove(formality.id, docIndex)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex justify-center space-x-2">
                                        {!formality.sendDate && (
                                            <>
                                                <button
                                                    className={`p-2 rounded-lg px-8 bg-transparent text-blue-logo-color border-2 ${isAllDocumentsRequisUploaded(formality) ? 'border-blue-logo-color hover:bg-blue-logo-color hover:text-white' : 'border-gray-300 text-gray-300 cursor-not-allowed'}`}
                                                    disabled={!isAllDocumentsRequisUploaded(formality)}
                                                    onClick={() => handleSendFormality(formality.id)}
                                                >
                                                    Envoyer
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        // Rendu pour PC
        return (
            <div ref={scrollableTableRef} className="overflow-x-auto mt-6">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Date de demande
                                <button onClick={() => handleSort('dateReservation')}>🔽</button>
                            </th>
                            <th scope="col" className="px-6 py-3">Formalité</th>
                            <th scope="col" className="px-6 py-3">Dénomination</th>
                            <th scope="col" className="px-6 py-3">Montant HT</th>
                            <th scope="col" className="px-6 py-3">Contact</th>
                            <th scope="col" className="px-6 py-3">Facture</th>
                            <th scope="col" className="px-6 py-3">
                                Statut
                                <button onClick={() => handleSort('status')}>🔽</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {sortFormalities(formalities).map((formality, index) => (
                                <React.Fragment key={`fragment-${formality.dateReservation}-${index}`}>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}</td>
                                        <td className="px-6 py-4">{Capitalize(formality.categorie)} {">"} {Capitalize(formality.titreFormalitie)}</td>
                                        <td className="px-6 py-4">{formality.nomEntreprise}</td>
                                        <td className="px-6 py-4">{formality.montantHT} €</td>
                                        <td className="px-6 py-4">{formality.prenom} {formality.nom}<br />{formality.email_contact}</td>
                                        <td className="px-6 py-4">
                                            <a href={formality.invoice_pdf_url} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger</a>
                                        </td>
                                        <td className={`px-6 py-4 ${getStatusClass(formality.status)}`}>{formality.status} {formality?.urlRecepisse &&
                                            <a href={formality.urlRecepisse} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger le récépissé</a>}
                                        </td>
                                    </tr>
                                    <tr onClick={() => toggleExpand(formality.id)} className="bg-sky-100 cursor-pointer border-b dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                                        <td className="px-6 text-center" colSpan="6">
                                            <div className='text-blue-logo-color'>
                                                Documents & pièces requis : ({formality?.upload_number_file || 0} / {formality.required_documents.length})
                                            </div>
                                        </td>
                                        <td className="px-6 text-center">
                                            <div className="flex items-center justify-center space-x-1">
                                                <div className="flex items-center justify-center space-x-1">
                                                    {formality.sendDate ? (
                                                        <span className="text-blue-logo-color"> {displayFormattedDate(formality.sendDate)}</span>
                                                    ) : (
                                                        <>
                                                            <button
                                                                className={`p-2 rounded-lg px-8 bg-transparent text-blue-logo-color border-2 ${isAllDocumentsRequisUploaded(formality) ? 'border-blue-logo-color hover:bg-blue-logo-color hover:text-white' : 'border-gray-300 text-gray-300 cursor-not-allowed'}`}
                                                                disabled={!isAllDocumentsRequisUploaded(formality)}
                                                                onClick={() => handleSendFormality(formality.id)}
                                                            >
                                                                Envoyer
                                                            </button>
                                                        </>
                                                    )}
                                                    <button
                                                        className="flex items-center justify-center"
                                                    >
                                                        {expandedRows[formality.id] ? <IoTriangle className='text-blue-logo-color' /> : <IoCaretDown className='text-blue-logo-color w-5 h-5' />}
                                                    </button>
                                                </div>

                                            </div>
                                        </td>

                                    </tr>
                                    {
                                        expandedRows[formality.id] && (
                                            <tr className='bg-sky-100 border-b'>
                                                <td colSpan="8">
                                                    <div className='py-4 w-full max-w-4xl mx-auto'>
                                                        {formality.required_documents.map((doc, docIndex) => (
                                                            <div key={docIndex} className="mb-8">
                                                                <div className="mb-2 flex flex-col items-start">
                                                                    <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                                        {doc.titrePiece}
                                                                        {doc.required && <span className="text-red-500">*</span>}
                                                                        {doc.urlModel && (
                                                                            <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
                                                                                <BsDownload className='w-5 h-5 mr-2' />
                                                                                Télécharger modèle
                                                                            </a>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                                <FileUploadComponent
                                                                    docIndex={docIndex + '-' + doc.titrePiece}
                                                                    formalityId={formality.id}
                                                                    existingFileUrl={doc.fileUrl}
                                                                    existingFileName={doc.fileName}
                                                                    existingUploadDate={doc.uploadDate}
                                                                    isSent={!!formality.sendDate}
                                                                    onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                                        console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                                        handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                                    }}
                                                                    onUploadRemove={() => {
                                                                        console.log(`Fichier a été supprimé avec succès`);
                                                                        handleFileUploadRemove(formality.id, docIndex)
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}

                                                        <label className="block text-sm font-bold text-blue-logo-color dark:text-white mb-1">
                                                            Nouveau document
                                                        </label>
                                                        <div key={formality.id} className='flex w-full items-center'>
                                                            <input
                                                                type='text'
                                                                style={{ flex: '3' }}
                                                                value={documentTitles[formality.id] || ''} // Utiliser l'ID de la formalité pour différencier les états
                                                                onChange={(e) => handleTitleChange(formality.id, e.target.value)}
                                                                placeholder='Titre du document...'
                                                                className='bg-white border-slate-400 border-2 rounded-lg p-3 text-black flex-grow mr-2'
                                                                id={`inputTitreDocument-${formality.id}`}
                                                            />
                                                            <label
                                                                className="bg-blue-400 text-white rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-center hover:text-gray-500 flex-shrink-0 p-3"
                                                                style={{ flex: '1' }}>
                                                                <span className="text-base leading-normal">Chercher un fichier</span>
                                                                <input
                                                                    type="file"
                                                                    className="hidden"
                                                                    disabled={!documentTitles[formality.id]}
                                                                    onChange={(e) => {
                                                                        const titrePiece = documentTitles[formality.id];
                                                                        handleFileChange(e, formality.id, `${formality.required_documents.length}-${titrePiece}`);
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            ))}
                        </>
                    </tbody>
                </table>
            </div>
        );
    }
};

export default FormalitiesTable;
