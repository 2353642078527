
const ErrorMessage = ({ showError, message }) => {
    if (!showError) {
        return null;
    }

    return <p className="text-red-500">{message}</p>;
};


const ContactInformationForm = ({
    companyName,
    sirenNumber,
    tel,
    categoryFormalitie,
    titleFormalitie,
    prenom,
    nom,
    email,
    attemptedSubmit,

    handleCompanyNameChange,
    handleSirenNumberChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handleTelChange,
    isCompanyNameValid,
    isSirenNumberValid,
    isFirstNameValid,
    isLastNameValid,
    isEmailValid,
    isTelValid,
    Capitalize,
    handleNextStepClick,
}) => {
    console.log("ContactInformationForm", Capitalize)
    return (
        <>
            {categoryFormalitie && titleFormalitie && (
                <>
                    <p className="leading-5	text-center text-2xl pl-4 font-bold text-first-oliver">
                        Informations de contact
                    </p>
                    <p className="leading-5 text-center text-xs pl-4 my-4 text-first-oliver">
                        {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)}
                    </p>
                    <p className="leading-5  my-4 justify-center">
                        Veuillez renseigner le n° Siret de l'entreprise concernées par la formalité et confirmer les informations de contact de la personne en charge de la démarche et de son suivi.
                    </p>
                </>
            )}
            <input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Dénomination de l'entreprise"
                className={`bg-light-white my-2 ${attemptedSubmit && !isCompanyNameValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                value={companyName}
                autoFocus={true}
                onChange={handleCompanyNameChange}
                required
            />
            <ErrorMessage showError={!companyName && attemptedSubmit} message="Veuillez entrer une dénomination d'entreprise valide." />

            {categoryFormalitie !== "creation" && (
                <>
                    <input
                        type="text"
                        name="sirenNumber"
                        id="sirenNumber"
                        placeholder="N° Siren"
                        className={`bg-light-white my-2 ${attemptedSubmit && !isSirenNumberValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                        value={sirenNumber}
                        onChange={handleSirenNumberChange}
                        required
                    />
                    <ErrorMessage showError={attemptedSubmit && !sirenNumber} message="Veuillez entrer un numéro SIREN valide." />
                </>
            )}

            <input
                type="text"
                name="prenom"
                id="prenom"
                placeholder="Prénom"
                className={`bg-light-white my-2 ${attemptedSubmit && !isFirstNameValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                value={prenom}
                onChange={handleFirstNameChange}
                required
            />
            <ErrorMessage showError={!prenom && attemptedSubmit} message="Veuillez entrer un prénom valide." />

            <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom"
                className={`bg-light-white my-2 ${attemptedSubmit && !isLastNameValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                value={nom}
                onChange={handleLastNameChange}
                required
            />
            <ErrorMessage showError={!nom && attemptedSubmit} message="Veuillez entrer un nom valide." />

            <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                className={`bg-light-white my-2 ${attemptedSubmit && !isEmailValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                value={email}
                onChange={handleEmailChange}
                required
            />
            <ErrorMessage showError={!email && attemptedSubmit} message="Veuillez entrer un email valide." />

            <input
                type="tel"
                name="tel"
                id="tel"
                placeholder="Téléphone"
                className={`bg-light-white my-2 ${attemptedSubmit && !isTelValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                value={tel}
                onChange={handleTelChange}
                required
            />
            <ErrorMessage showError={!tel && attemptedSubmit} message="Veuillez entrer un numéro de téléphone valide." />

            <button
                className="p-2 rounded-lg px-8 bg-blue-logo-color text-white border-2 border-blue-logo-color hover:bg-transparent hover:text-blue-logo-color transition-colors duration-150"
                onClick={handleNextStepClick}
            // disabled={
            //     !companyName ||
            //     (categoryFormalitie !== "creation" && !sirenNumber) ||
            //     !prenom || !nom ||
            //     !email || !tel ||
            //     !isCompanyNameValid ||
            //     (categoryFormalitie !== "creation" && !isSirenNumberValid) ||
            //     !isFirstNameValid || !isLastNameValid ||
            //     !isEmailValid || !isTelValid
            // }
            >
                Continuer
            </button>
        </>
    );
};


export default ContactInformationForm;

