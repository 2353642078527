import React, { useState, useEffect, useRef } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';
import { MdModeEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';

function ConversationsList({ conversations, handleSelectConversation, editingId, newTitle, setNewTitle, saveNewTitle, promptRename, setModalConfiramtionDelete, setConvoId }) {
    const [selectedConvoId, setSelectedConvoId] = useState(null);
    const [menuPosition, setMenuPosition] = useState({});
    const menuRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setSelectedConvoId(null); // Ferme le menu si clic à l'extérieur
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = (convo, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setMenuPosition({
            top: rect.top + window.scrollY - 10, // Décaler de 10 pixels vers le haut
            left: rect.left + window.scrollX - rect.width - 100 // Placer à gauche de l'élément cliqué
        });
        setSelectedConvoId(convo.id === selectedConvoId ? null : convo.id);
    };


    return (
        <div className='overflow-x-hidden'>
            {console.log("conversations.length: ", conversations.length)}
            {conversations.map(convo => (
                <div key={convo.id} className="p-2 rounded-md cursor-pointer hover:bg-light-white transition duration-300 ease-in-out">
                    <div className="flex justify-between">
                        <div className='w-full' onClick={() => handleSelectConversation(convo.id)}>
                            {editingId === convo.id ? (
                                <input
                                    type="text"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    onBlur={() => saveNewTitle(convo.id)}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { saveNewTitle(convo.id); } }}
                                    className="text-base font-semibold text-[#06094F] rounded p-1"
                                />
                            ) : (
                                <div className="inline-block w-[250px]">
                                    <h3 className="leading-5 text-base text-white line-clamp-1" title={convo.titre}>
                                        {convo.titre}
                                    </h3>

                                    <p className="text-sm text-[#487FB8]">
                                        {convo.lastActive && (
                                            <>
                                                {
                                                    new Date(convo.lastActive.seconds * 1000).toLocaleDateString("fr-FR", {
                                                        year: 'numeric', month: 'long', day: 'numeric'
                                                    })
                                                } à {new Date(convo.lastActive.seconds * 1000).toLocaleTimeString("fr-FR", {
                                                    hour: '2-digit', minute: '2-digit'
                                                })}
                                            </>
                                        )}
                                    </p>

                                </div>
                            )}
                        </div>
                        <button onClick={(e) => toggleMenu(convo, e)}>
                            <SlOptionsVertical size={25} color="#fff" className='hover:scale-110' />
                        </button>
                        {selectedConvoId === convo.id && (
                            <div ref={menuRef} style={{ ...menuPosition }} className="absolute bg-white shadow-lg rounded-lg p-2 w-40 mt-12 z-10">
                                <div className="flex items-center space-x-2 p-1 hover:bg-gray-100 cursor-pointer" onClick={() => {
                                    promptRename(convo.id, convo.titre);
                                    setSelectedConvoId(null);
                                }}>
                                    <MdModeEdit size={20} color="#4B5563" />
                                    <span>Renommer</span>
                                </div>
                                <div className="flex items-center space-x-2 p-1 hover:bg-gray-100 cursor-pointer" onClick={() => {
                                    setModalConfiramtionDelete(true);
                                    setConvoId(convo.id);
                                }}>
                                    <RiDeleteBinLine size={20} color="#EF4444" />
                                    <span>Supprimer</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ConversationsList;