import React, { useState, useEffect } from "react";
import { fetchData } from "../../utils/ask_api";
import { useAppContext } from "../../context/AppContext";
import { RequestsChart } from "./RequestsChart"
import EvaluationStats from "./EvaluationStats";

const ParamsOliver = ({ }) => {
  const [kDoc, setKDoc] = useState(0);
  const [expertThreshold, setExpertThreshold] = useState(0);
  const [solutionThreshold, setSolutionThreshold] = useState(0);
  const [liensUtilesThreshold, setLiensUtilesThreshold] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [kLiensUtiles, setKLiensUtiles] = useState(0);
  const { model, version, setModel, setVersion } = useAppContext();
  const [modelDefault, setModelDefault] = useState("");

  const modelsInfogreffe = {
    mistral: "Open Mixtral 8x7b",
    mistralMedium: "Mistral medium",
    openai: "GPT 3.5",
    openai_4: "GPT 4 turbo"
  }

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const data = await fetchData(`get_app_settings`, null, "GET");
        setKDoc(data.k_documents);
        setExpertThreshold(data.expert_treshold);
        setSolutionThreshold(data.solution_treshold);
        setLiensUtilesThreshold(data.liens_utiles_threshold);
        setKLiensUtiles(data.k_liens_utiles);
        setModelDefault(data.default_model);

      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchDataFromApi();
  }, []);

  const clearStatusMessage = () => {
    setStatusMessage("");
  };

  function updateDisplay(modelDefault) {
    console.log(`Le modèle choisi est maintenant : ${modelDefault}`);
  }

  useEffect(() => {
    let timer;
    if (statusMessage) {
      timer = setTimeout(clearStatusMessage, 4000); // 4000 milliseconds = 4 seconds
    }
    return () => clearTimeout(timer); // This will clear the timer when the component unmounts or before setting a new timer
  }, [statusMessage]);

  useEffect(() => {
    updateDisplay(modelDefault);
  }, [modelDefault]);

  const handleModifySettings = async () => {
    try {
      const payload = {
        k_documents: kDoc,
        expert_treshold: expertThreshold,
        solution_treshold: solutionThreshold,
        liens_utiles_threshold: liensUtilesThreshold,
        k_liens_utiles: kLiensUtiles,
        default_model: modelDefault
      };
      await fetchData(`update_app_settings`, payload, "POST");
      setStatusMessage("Paramêtres modifiés avec succès");
      setStatusColor("green");
      switch (modelDefault) {
        case "Open Mixtral 8x7b":
          setModel("mistral")
          setVersion(0)
          break;
        case "Mistral medium":
          setModel("mistral")
          setVersion(1)
          break;
        case "GPT 3.5":
          setModel("openai")
          setVersion(0)
          break;
        case "GPT 4 turbo":
          setModel("openai")
          setVersion(0)
          break;
        default:
          console.log(modelDefault)
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setStatusMessage("Erreur lors de la modification des paramêtres");
      setStatusColor("red");
    }
  };

  return (
    <div className="p-4 space-y-4 w-full">
      <h1 className="underline font-extrabold text-xl mb-4">
        Modification des paramêtres
      </h1>
      <div className="flex flex-wrap w-full items-end">
        <div className="w-1/3 p-2">
          <p>Nombre de documents</p>
          <input
            className="p-1 input input-bordered w-full"
            type="number"
            value={kDoc}
            min={0}
            onChange={(e) => setKDoc(e.target.value)}
          />
        </div>
        <div className="w-1/3 p-2">
          <p>Seuil de sélection des experts</p>
          <input
            className="p-1 input input-bordered w-full"
            type="number"
            max={100}
            min={0}
            value={expertThreshold}
            onChange={(e) => setExpertThreshold(e.target.value)}
          />
        </div>
        <div className="w-1/3 p-2">
          <p>Seuil de sélection des solutions</p>
          <input
            className="p-1 input input-bordered w-full"
            type="number"
            max={100}
            min={0}
            value={solutionThreshold}
            onChange={(e) => setSolutionThreshold(e.target.value)}
          />
        </div>
        <div className="w-1/3 p-2">
          <p>Seuil de sélection des liens utiles</p>
          <input
            className="p-1 input input-bordered w-full"
            type="number"
            max={100}
            min={0}
            value={liensUtilesThreshold}
            onChange={(e) => setLiensUtilesThreshold(e.target.value)}
          />
        </div>
        <div className="w-1/3 p-2">
          <p>Nombre de liens utiles à afficher</p>
          <input
            className="p-1 input input-bordered w-full"
            type="number"
            max={100}
            min={0}
            value={kLiensUtiles}
            onChange={(e) => setKLiensUtiles(e.target.value)}
          />
        </div>
        <div className="w-1/3 p-2">
          <p>Modèle utilisé par défaut</p>
          <select
            className="p-1 input input-bordered w-full"
            value={modelDefault}
            onChange={(e) => setModelDefault(e.target.value)}
          >
            {Object.entries(modelsInfogreffe).map(([key, name]) => (
              <option value={name} key={key} className="form-radio h-5 w-5 mr-2">
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex flex-col items-center mt-4">
        {statusMessage && <div className={"mt-4"} style={{ color: statusColor }}> {statusMessage} </div>}
        <button
          onClick={() => {
            handleModifySettings();
          }}
          type="submit"
          className="p-1 rounded-md cursor-pointer btn text-base border btn-primary px-4"
        >
          Mettre à jour
        </button>
      </div>
      <div className="flex flex-col items-center justify-center min-h-screen px-4 lg:px-8">
        <div className="w-full lg:w-3/4 xl:w-2/3 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-center">Statistiques des Requêtes</h2>
          <div className="relative">
            <RequestsChart />
          </div>
        </div>
        <div className="w-full lg:w-3/4 xl:w-2/3 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-center">Évaluations des Utilisateurs</h2>
          <div className="relative">
            <EvaluationStats />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParamsOliver;
