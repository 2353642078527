import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";

const SelectModel = () => {


  const models = {
    openai: "OpenAI",
    mistral: "Mistral AI",
  };

  const modelsInfogreffe = {
    mistral: "Open Mixtral 8x7b",
    mistralMedium: "Mistral medium",
    openai: "GPT 3.5",
    openai_4: "GPT 4 turbo"
  }

  const { nameModel, subdomain, setNameModel } = useAppContext();
  const [selectedModelData, setSelectedModelData] = useState(nameModel);

  useEffect(() => {
    setSelectedModelData(nameModel);
  }, [nameModel]);

  return (
    <div className="h-80">
      <div className="flex flex-col">

        {Object.entries(modelsInfogreffe).map(([key, name]) => (
          <label key={key} className="inline-flex items-center mt-3">
            <input
              type="radio"
              name="model"
              value={name}
              checked={name === selectedModelData}
              onChange={(e) => {
                setSelectedModelData(e.target.value);
                setNameModel(e.target.value);
              }}
              className="form-radio h-5 w-5 mr-2"
            />
            {name}
          </label>
        ))}
      </div>
    </div>
  );
};

export default SelectModel;
