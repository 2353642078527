import React, { useState } from 'react';
import Modal from "../Modal";
import logoInfogreffe from "../../assets/logoOliver_beta3.png";
import { fetchData } from '../../utils/ask_api';
import { useAppContext } from '../../context/AppContext';


const ErrorMessage = ({ showError, message }) => {
    if (!showError) {
        return null;
    }

    return <p className="text-red-500">{message}</p>;
};


const ContactExpertInformationForm = ({
    modalContactExpertInformationOpen,
    setModalContactExpertInformationOpen,
}) => {
    const [companyName, setCompanyName] = useState("");
    const [sirenNumber, setSirenNumber] = useState("");
    const [tel, setTel] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email_expert, setEmail] = useState("");

    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
    const [isSirenNumberValid, setIsSirenNumberValid] = useState(true);
    const [isTelValid, setIsTelValid] = useState(true);
    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isDomaineExprtiseValid, setIsDomaineExprtiseValidValid] = useState(true);

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptCgus, setAcceptCgus] = useState(false);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [messageError, setMessageError] = useState(null);

    const [modalCguOpen, setModalCguOpen] = useState("");
    const [domaineExprtise, setDomaineExprtise] = useState("");
    const [modalTile, setModalTitle] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);


    const { currentUser } = useAppContext();

    const handleValiderClick = async () => {
        setAttemptedSubmit(true);
        if (
            companyName &&
            sirenNumber &&
            firstname && lastName &&
            email_expert && tel &&
            isCompanyNameValid &&
            isSirenNumberValid &&
            isFirstNameValid && isLastNameValid &&
            isEmailValid && isTelValid
        ) {
            console.log("referencer cette expert");
            try {
                const contactExpertData = {
                    dateEnregistement: new Date().toISOString(),
                    prenom: firstname,
                    nom: lastName,
                    email_expert: email_expert,
                    telephone: tel,
                    nomEntreprise: companyName,
                    siret: sirenNumber,
                    domaineExprtise: domaineExprtise
                };
                console.log("fetch contactExpertData:", contactExpertData)
                const d = await fetchData("referencer-expert", contactExpertData, "POST");
                console.log(d);
                resetForm();
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            }
        }
    }

    const resetForm = () => {
        setCompanyName("");
        setSirenNumber("");
        setDomaineExprtise("");
        setFirstName("");
        setLastName("");
        setTel("");
        setEmail("")

        setModalOpen(true);
        setIsCompanyNameValid(true);
        setIsSirenNumberValid(true);
        setIsDomaineExprtiseValidValid(true);
        setIsFirstNameValid(true);
        setIsLastNameValid(true);
        setIsTelValid(true);
        setIsEmailValid(true);

        setAcceptCgus(false);
        setAcceptTerms(false);
        setModalContactExpertInformationOpen(false);
        setAttemptedSubmit(false);
    };

    const validateEmail = (email_expert) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_expert.toLowerCase());
    };
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));

    };

    const handleFirstNameChange = (e) => {
        const firstNameValue = e.target.value.trim();
        setFirstName(firstNameValue);
        setIsFirstNameValid(firstNameValue.length > 0);

    };

    const handleLastNameChange = (e) => {
        const lastNameValue = e.target.value.trim();
        setLastName(lastNameValue);
        setIsLastNameValid(lastNameValue.length > 0);

    };

    const handleDomainChange = (e) => {
        const domaineExprtiseValue = e.target.value;
        setDomaineExprtise(domaineExprtiseValue);
        setIsDomaineExprtiseValidValid(domaineExprtiseValue.length > 0);

    };

    const handleCompanyNameChange = (e) => {
        const companyNameValue = e.target.value;
        setCompanyName(companyNameValue);
        setIsCompanyNameValid(companyNameValue.length > 0);
    };

    const handleSirenNumberChange = (e) => {
        const value = e.target.value.trim();
        const regex = /^\d{9}$/;
        const isValid = regex.test(value);
        setIsSirenNumberValid(isValid);
        setSirenNumber(value);
    };

    const handleTelChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9+\s()-]+$/;
        const isValid = regex.test(value);
        console.log(isValid)
        setIsTelValid(isValid);
        setTel(value);
    };

    return (
        <>
            <Modal
                modalOpen={modalContactExpertInformationOpen}
                setModalOpen={setModalContactExpertInformationOpen}
                title=""
                modalSize={"cgus"}
            >
                <div className={`pb-8 w-full`} >
                    <div className="md:px-24">
                        <div className="signupFormContainer w-ful">
                            <img src={logoInfogreffe} alt="logoInfogreffe" className="w-40 mb-6" />
                            <div
                                className="signupFormContainerForm loginContainerContent flex flex-col justify-start items-start w-full"
                            >
                                <p className="leading-5 justify-center my-4">
                                    Pour être référencé en tant qu'expert sur Oliver, veuillez renseigner les informations suivantes s'il vous plait :
                                </p>

                                <p className="leading-5 text-first-oliver font-bold"> Identité de l'expert</p>
                                <input
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    placeholder="Dénomination de l'entreprise"
                                    className={`bg-light-white my-2 ${attemptedSubmit && !isCompanyNameValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                                    value={companyName}
                                    autoFocus={true}
                                    onChange={handleCompanyNameChange}
                                    required
                                />
                                <ErrorMessage showError={!companyName && attemptedSubmit} message="Veuillez entrer une dénomination d'entreprise valide." />

                                <input
                                    type="text"
                                    name="sirenNumber"
                                    id="sirenNumber"
                                    placeholder="N° Siren"
                                    className={`bg-light-white my-2 ${attemptedSubmit && !isSirenNumberValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                                    value={sirenNumber}
                                    onChange={handleSirenNumberChange}
                                    required
                                />
                                <ErrorMessage showError={!sirenNumber && attemptedSubmit} message="Veuillez entrer un numéro SIREN valide." />

                                <textarea
                                    className={`bg-light-white my-2 ${attemptedSubmit && !isDomaineExprtiseValid ? 'border-red-400' : 'border-slate-400'} border-2 rounded-lg w-full p-2 text-black`}
                                    placeholder="Domaine(s) d'expertise sur le(s)quel(s) l'expert souhaite être référencé"
                                    value={domaineExprtise}
                                    onChange={handleDomainChange}
                                />

                                <ErrorMessage showError={!domaineExprtise && attemptedSubmit} message="Veuillez entrer de(s) Domaine(s) valide." />


                                <p className="leading-5 text-first-oliver font-bold"> Informations de contact</p>

                                <div className='flex flex-wrap w-full gap-2'>
                                    <div className="flex flex-col flex-1 min-w-0">
                                        <input
                                            type="text"
                                            name="prenom"
                                            id="prenom-f"
                                            placeholder="Prénom"
                                            className="bg-light-white my-2 border-slate-400 border-2 rounded-lg w-full p-2 text-black"
                                            value={firstname}
                                            onChange={handleFirstNameChange}
                                            required
                                            style={{ borderColor: isFirstNameValid ? "" : "red" }}
                                        />
                                        <ErrorMessage showError={!firstname && attemptedSubmit} message="Veuillez entrer un prénom valide." />
                                    </div>
                                    <div className="flex flex-col flex-1 min-w-0">
                                        <input
                                            type="text"
                                            name="nom"
                                            id="nom-f"
                                            placeholder="Nom"
                                            className="bg-light-white my-2 border-slate-400 border-2 rounded-lg w-full p-2 text-black"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                            required
                                            style={{ borderColor: isLastNameValid ? "" : "red" }}
                                        />
                                        <ErrorMessage showError={!lastName && attemptedSubmit} message="Veuillez entrer un nom valide." />
                                    </div>
                                </div>


                                <input
                                    type="text"
                                    name="email_expert"
                                    id="email_expert"
                                    placeholder="Email"
                                    className="bg-light-white my-2 border-slate-400 border-2 rounded-lg w-full p-2 text-black"
                                    value={email_expert}
                                    onChange={handleEmailChange}
                                    required
                                    style={{ borderColor: isEmailValid ? "" : "red" }}
                                />
                                <ErrorMessage showError={!email_expert && attemptedSubmit} message="Veuillez entrer un Email valide." />

                                <input
                                    type="tel"
                                    name="tel"
                                    id="tel-f"
                                    placeholder="Télephone"
                                    className="bg-light-white my-2 border-slate-400 border-2 rounded-lg w-full p-2 text-black"
                                    value={tel}
                                    onChange={handleTelChange}
                                    required
                                    style={{ borderColor: isTelValid ? "" : "red" }}
                                />
                                <ErrorMessage showError={!tel && attemptedSubmit} message="Veuillez entrer un telephone valide." />

                            </div>
                        </div>

                        <div className='mb-5'>
                            <div className="text-xs">
                                <label>
                                    <input
                                        type="checkbox"
                                        id="termsCheckbox"
                                        checked={acceptTerms}
                                        onChange={(e) => setAcceptTerms(e.target.checked)}
                                        style={{ marginRight: "5px" }}
                                    />
                                    En cochant cette case, je consens au traitement de mes
                                    données personnelles. Je dispose d'un droit d'accès, de
                                    rectification et d'opposition aux données me concernant en
                                    contactant Legaware. Pour plus d'informations, consultez
                                    notre <span
                                        onClick={() => { setModalTitle("Politique de confidentialité"); setModalCguOpen(true) }}
                                        className="text-blue-500 underline cursor-pointer">POLITIQUE DE CONFIDENTIALITÉ.</span>{" "}
                                </label>
                            </div>
                            <div className="text-xs">
                                <input
                                    type="checkbox"
                                    id="cgusCheckbox"
                                    className="mr-2"
                                    checked={acceptCgus}
                                    onChange={(e) => setAcceptCgus(e.target.checked)}
                                />
                                <label htmlFor="cgusCheckbox">
                                    En cochant cette case, je reconnais avoir pris connaissance
                                    et accepté les{" "}
                                    <span
                                        onClick={() => { setModalTitle("CGU"); setModalCguOpen(true) }}
                                        className="text-blue-500 underline cursor-pointer">
                                        CONDITIONS GÉNÉRALES DE VENTES ET D'UTILISATION DU SITE
                                    </span> <span>
                                        infogreffe.oliver.legal.
                                    </span>
                                </label>
                            </div>
                            <ErrorMessage showError={(!acceptTerms || !acceptCgus) && attemptedSubmit} message="Pour continuer, veuillez accepter les conditions générales d'utilisation et la politique de confidentialité." />

                        </div>

                        <div className="flex justify-center">
                            <button
                                className="p-2 rounded-lg px-8 bg-blue-logo-color text-white border-2 border-blue-logo-color hover:bg-transparent hover:text-blue-logo-color transition-colors duration-150"
                                onClick={handleValiderClick}
                            >
                                Valider
                            </button>
                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                title="Message"
                modalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modalSize={"auto"}
            >
                <div className="flex flex-col items-center justify-center p-4">
                    <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" />
                    <p className="text-center mb-6"> La demande a été transmise. </p>
                    <button
                        onClick={() => setModalOpen(false)}
                        className="signupFormContainerContinuer text-white font-bold"
                    >
                        OK
                    </button>
                </div>
            </Modal>
        </>
    );
};


export default ContactExpertInformationForm;

