import { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { onSnapshot, doc } from "firebase/firestore";
import { useAppContext } from "../../context/AppContext";
import FormalitiesTable from "./FormalitiesTable";

const Formalities = ({ setOpen }) => {
    const { currentUser } = useAppContext();
    const [formalities, setFormalities] = useState(null);

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = onSnapshot(
                doc(db, `formalities/${currentUser.uid}`),
                (docSnapshot) => {
                    const data = docSnapshot.data();
                    console.log(data);

                    // Vérifiez que data n'est pas undefined
                    if (data) {
                        // Transformez l'objet des formalités en tableau et incluez la clé comme 'id'
                        const formalitiesWithId = Object.entries(data).map(([key, value]) => ({
                            ...value,
                            id: key, // Utilisez la clé comme identifiant unique
                        }));

                        console.log(formalitiesWithId);
                        setFormalities(formalitiesWithId);
                    }
                }
            );

            return () => unsubscribe();
        }
    }, [currentUser]);

    return (
        <>
            <div className="w-full">
                {formalities && <FormalitiesTable formalities={formalities} setFormalities={setFormalities} />}
            </div>
        </>
    );
};

export default Formalities;
