import React from "react";
import { useEffect } from "react";
import { useAppContext } from "../../../context/AppContext.js";
import moment from "moment";
import "moment/locale/fr";

const UserMessage = ({ message_id, index, content, createdAt }) => {
  const { selectedOption } = useAppContext();

  return (
    <div>
      {selectedOption?.class && message_id !== 0 ? (
        <div className={`flex flex-row-reverse`}>
          <div className={`${selectedOption.class}`}>
            {content.toUpperCase()}{" "}
          </div>
        </div>
      ) : (
        <>
          {content.length > 0 && (
            <p
              className={`text-right text-blue-logo-color text-lg`}
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          )}
        </>
      )}

      <div className={`text-right message__createdAt`}>
        {moment(createdAt).calendar()}
      </div>
    </div>
  );
};

export default UserMessage;
