import React, { useState, useEffect } from 'react';
import { FiUpload } from "react-icons/fi";
import { fetchData } from '../../utils/ask_api';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useContinuousProgress from '../../hooks/useContinuousProgress';



const FileUploadKnowledgebase = ({ label, infosFile }) => {
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const continuousProgress = useContinuousProgress(0, 100);


    useEffect(() => {
        if (!taskId) return;
        var timeout = 1000

        const interval = setInterval(() => {
            fetchData(`tasks/${taskId}`, {}, 'GET')
                .then(data => {
                    if (!data) {
                        console.error('Empty response from server');
                        return;
                    }
                    console.log(`tasks/${taskId}==>`);
                    console.log(data);

                    if (data.task_status === "PROGRESS") {
                        if (data.task_meta_data) {
                            setIsCalculating(false);
                            if (data.task_meta_data.progress === 25 || data.task_meta_data.progress === 90) {
                                timeout = 10000
                                setIsCalculating(true);
                            }
                            setProgress(data.task_meta_data.progress || 0);
                            setStatusMessage(data.task_meta_data.message || "Traitement en cours...");
                        }
                        setUploading(true);
                    } else if (data.task_status === "COMPLETE" || data.task_status === "SUCCESS") {
                        setProgress(100);
                        setStatusMessage(data.task_result.message || "Traitement terminé avec succès.");
                        setIsCalculating(false);
                        setUploading(false);
                        clearInterval(interval);
                    } else if (data.task_status === "FAILURE") {
                        setStatusMessage("Erreur lors du traitement.");
                        setIsCalculating(false);
                        setUploading(false);
                        clearInterval(interval);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    clearInterval(interval);
                });
        }, timeout);  // Interroge toutes les 10 secondes

        return () => clearInterval(interval);
    }, [taskId]);


    useEffect(() => {
        console.log('FileUploadKnowledgebase mounted');
        return () => {
            console.log('FileUploadKnowledgebase unmounted');
        };
    }, []);

    //   useEffect(() => {
    //     console.log('FileUploadKnowledgebase updated');
    //   });


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log("file:", file);
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous le type de fichier est zip ou json.");
            return;
        } else {
            console.log("handleFileChange");
            uploadFile(file);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length && isValidFile(files[0])) {
            uploadFile(files[0]);
        } else {
            alert("Fichier invalide. Assurez-vous le type de fichier est zip ou json");
        }
    };

    const isValidFile = (file) => {
        const allowedTypes = ['application/zip', 'application/x-zip-compressed', 'application/json'];
        return allowedTypes.includes(file.type);
    };

    const uploadFile = (file) => {
        setUploading(true);

        const payload = {
            file: file,
            label: label,
        };
        fetchData('api/upload', payload, 'POST', true)
            .then(data => {
                console.log('Success:', data);
                setTaskId(data.task_id);
                setStatusMessage("Fichier en cours de traitement...");
            })
            .catch((error) => {
                console.error('Error:', error);
                setUploading(false);
            });
    };
    if (label === "legifrance") {
        return <p>WIP ...</p>
    }

    const resumeProccess = (e) => {
        e.preventDefault()
        if (infosFile?.saved_data) {
            setUploading(true);

            const payload = {
                filename: infosFile.fileName,
                size_qdrant: infosFile.nbElemInQdrant,
                label: label,
            };
            fetchData('api/retry_task_sp', payload, 'POST', true)
                .then(data => {
                    console.log('Success:', data);
                    setTaskId(data.task_id);
                    setStatusMessage("Fichier en cours de traitement...");
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setUploading(false);
                });
        }
    };

    return (
        <>
            {/* {console.log("label: ", label)} */}
            {!uploading && !infosFile?.saved_data && (
                <div className='bg-gray-200 rounded-lg p-4 flex flex-col items-center mb-2'>
                    <label
                        className="w-full flex flex-col items-center px-4 py-10 bg-gray-100 text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-center hover:text-blue-logo-color"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                    >
                        <FiUpload className="text-3xl" />
                        <span className="mt-2 text-base leading-normal">Faites glisser votre fichier ici, ou cliquez pour sélectionner un fichier</span>
                        <input type="file" className="hidden" onChange={handleFileChange} />
                    </label>
                </div>
            )}

            {(uploading) && !infosFile?.saved_data && (
                <>
                    <div className="text-center" style={{ width: '50%', margin: '0 auto' }}>
                        <CircularProgressbar
                            value={isCalculating ? continuousProgress : progress}
                            text={`${isCalculating ? 'Calcul en cours...' : `${Math.round(progress)}%`}`}
                            styles={buildStyles({
                                pathTransition: isCalculating ? "none" : "stroke-dashoffset 0.5s ease 0s",
                                pathColor: `rgba(62, 152, 199, ${progress / 100})`,
                                textColor: '#f88',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                                strokeWidth: 6,
                                textSize: isCalculating ? '8px' : '16px'
                            })}
                        />
                    </div>
                    <div className='mt-2 text-center'>{statusMessage}</div>
                </>
            )}

            {infosFile && infosFile.fileName && !uploading && (
                <div className="mt-4 w-full">
                    {!infosFile?.saved_data ? (
                        <>
                            Le fichier <span className='italic'>{infosFile.fileName} </span> a été traité avec succès.
                            <p>Nombre total d'enregistrements : {infosFile.nbElemInQdrant}</p>
                        </>
                    ) :
                        (
                            <>
                                Le fichier <span className='italic'>{infosFile.fileName} </span> est en cours de traitement.
                                <div className="flex justify-center">

                                    <button
                                        className="p-2 rounded-lg px-8 bg-blue-logo-color text-white border-2 border-blue-logo-color hover:bg-transparent hover:text-blue-logo-color transition-colors duration-150"
                                        onClick={resumeProccess}
                                    >
                                        Continuer
                                    </button>

                                </div>
                            </>

                        )
                    }

                </div>
            )}
        </>
    );
};

export default FileUploadKnowledgebase;

