import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import Modal from '../../components/Modal';
import FileUploadKnowledgebase from "./Knowledgebase";
import { MdOutlineDriveFolderUpload } from 'react-icons/md';
import logoInfogreffe from "../../assets/logoOliver_beta3.png";

const NestedCheckboxHelper = ({ nodes, ancestors, onBoxChecked, names }) => {
    const { isAdmin } = useAppContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalLabel, setModalLabel] = useState('');

    const formatDate = (date) => {
        if (!date) return "";
        return new Date(date.seconds * 1000).toLocaleDateString();
    };

    const handleOpenModal = (label) => {
        setModalLabel(label);
        setModalOpen(true);
    };

    const prefix = ancestors.join(".");
    const whiteListParentId = [
        "economiegouvfr__fiches_pratiques_entreprises",
        "economiegouvfr__fiches_pratiques_particuliers",
        "travailemploigouvfr",
        "ecologiegouvfr__politiques_publiques",
        "service_public",
        "jurisprudence_anil",
        "legifrance",
        "dgccrf",
        "impotsgouvfr_actualite",
        "impotsgouvfr_questions",
        "avis_de_coordination_du_registre_du_commerce_et_des_societes_ccrcs",
        "guide_des_formalites_infogreffe",
        "signatures-service-client_infogreffe"
    ];
    // console.log("NestedCheckboxHelper: ", nodes);
    // // console.log(ancestors);
    // console.log(onBoxChecked);
    // console.log(names);
    // console.log("NestedCheckboxHelper: FIN");
    return (
        <ul>
            {nodes.map(({ label, checked, childrenNodes }) => {
                const id = `${prefix}.${label}`;
                const children = childrenNodes.length > 0 && (
                    <NestedCheckboxHelper
                        nodes={childrenNodes}
                        ancestors={[...ancestors, label]}
                        onBoxChecked={onBoxChecked}
                        names={names}
                    />
                );

                return (
                    <li key={id} className="pl-[10px]">
                        <input
                            type="checkbox"
                            name={id}
                            id={id}
                            value={label}
                            checked={checked}
                            onChange={(e) => onBoxChecked(e.target.checked, label, ancestors)}
                        />
                        <label className="pl-2 w-[90%]" htmlFor={id}>
                            {names[label]?.titre}
                            {isAdmin && whiteListParentId.includes(label) && (
                                <span className="text-xs ml-2 whitespace-nowrap italic overflow-hidden text-ellipsis">
                                    Dernière mise à jour le {formatDate(names[label]?.date_update)}
                                </span>
                            )}
                        </label>
                        {isAdmin && whiteListParentId.includes(label) && (
                            <>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenModal(label);
                                    }}
                                    className="ml-8"
                                    style={{ transform: 'translateY(6px)' }}
                                >
                                    <MdOutlineDriveFolderUpload size={25} />
                                </button>
                                <Modal
                                    title=""
                                    modalOpen={isModalOpen}
                                    setModalOpen={setModalOpen}
                                    modalSize="large"
                                >
                                    <div className="flex flex-col items-center justify-center p-4">
                                        <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" />
                                        <p className="m-2">Source pour {names[modalLabel]?.titre}</p>
                                        <FileUploadKnowledgebase label={modalLabel} infosFile={names[modalLabel]} />
                                    </div>
                                </Modal>
                            </>
                        )}
                        {children}
                    </li>
                );
            })}
        </ul>
    );
};

export default NestedCheckboxHelper;
