// import { useEffect, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';

import { db } from "../../../../firebase.config";
import { doc, getDoc } from "firebase/firestore";

const ConfirmationOrder = ({
    categoryFormalitie,
    titleFormalitie,
    Capitalize,
    nextStep,
    packGlobal,
    prixHT,
    honorairesAccompagnement,
    fraisRBE,
    fraisAnnonceLegale,
    fraisGreffe,
    montantTVA,
    totalTTC
}) => {


    console.log(categoryFormalitie, titleFormalitie);
    console.log("ConfirmationOrder", Capitalize)
    return (
        <>
            {categoryFormalitie && titleFormalitie && (
                <>
                    <p className="leading-5	text-center text-2xl pl-4 font-bold text-first-oliver">
                        Validation de la commande
                    </p>
                    <p className="leading-5 text-center text-xs pl-4 my-4 text-first-oliver">
                        {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)}
                    </p>
                    <p className="leading-5  my-4 justify-center">
                        Veuillez vérifier les informations de la commande avant de payer.
                    </p>
                    <div className="max-w-4xl mx-auto">
                        <div className="overflow-x-auto bg-white p-4 rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500">
                                <tbody>
                                    {/* Dossier juridique */}
                                    <tr className="bg-white">
                                        <td className="ps-4 pt-2 font-bold w-full">
                                            <strong> Formalités de {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)} </strong>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className='text-end w-full whitespace-nowrap'>Prix</td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 pt-2 w-full">
                                            <div className="flex flex-col gap-2">
                                                <div className="flex flex-col gap-2">
                                                    {/* Zone du titre avec Pack Standard */}
                                                    <p className='font-bold'>
                                                        {(categoryFormalitie === "modication" ? "Pack global Modif" : "Pack Standard")}
                                                    </p>
                                                    {/* Zone d’immatriculation avec l’icône et le texte */}
                                                    {(categoryFormalitie !== "modication" ? (
                                                        <>
                                                            <div className="flex items-center gap-2">
                                                                <IoMdCheckmark className="w-6 h-6 text-green-500" />
                                                                <span>Honoraires d’accompagnement</span>
                                                            </div>
                                                            <span className="text-amber-500 ml-8">On s'occupe de tout</span>
                                                        </>
                                                    ) : "")}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-right font-bold w-full whitespace-nowrap">
                                            <div>{packGlobal ? `${prixHT}` : `${honorairesAccompagnement}`} € </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="ps-4 pt-2 w-full">  <p className='font-bold mr-8'>Frais de greffe et de publication légale </p>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end font-bold w-full whitespace-nowrap">
                                            {packGlobal ? "" : `${fraisRBE} €`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 w-full"> Frais de publication légale
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full whitespace-nowrap">
                                            {packGlobal ? "" : `${fraisAnnonceLegale} €`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 w-full"> Frais de greffe
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full whitespace-nowrap">
                                            {packGlobal ? "" : `${fraisGreffe} €`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="overflow-x-auto bg-white p-4 rounded-lg shadow-2xl">
                            <table className="w-full text-sm text-left text-gray-500">
                                <tbody>

                                    <tr>
                                        <td className="ps-4 pt-2 mr-15">
                                            <div className='flex justify-between items-center'>
                                                <div>
                                                    <p className='font-bold'>Récapitulatif</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="px-4 pt-2 w-full">  <p className='font-bold mr-10'>Total commande HT</p>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end font-bold w-full whitespace-nowrap">
                                            {packGlobal ? `${prixHT} €` : `${(fraisGreffe + fraisRBE + honorairesAccompagnement + fraisAnnonceLegale)} €`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 w-full"> Total TVA (20%)
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full whitespace-nowrap">
                                            {montantTVA.toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 font-bold w-full" > Total TTC
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full whitespace-nowrap">
                                            {totalTTC.toFixed(2)} €
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
            <table>

            </table>

            <button
                className="p-2 rounded-lg px-8 bg-blue-logo-color text-white border-2 border-blue-logo-color hover:bg-transparent hover:text-blue-logo-color transition-colors duration-150"
                onClick={nextStep}
            >
                Continuer
            </button>
        </>
    );
};


export default ConfirmationOrder;