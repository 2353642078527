import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

const Faq = ({}) => {
  const { customOliver } = useAppContext();
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    if (customOliver["faq"] != undefined) {
      setFaq(customOliver["faq"]);
    }
  }, [customOliver]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Event handler to change the selected item
  const handleSelectItem = (index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <div className="space-y-2">
      {faq.map((item, index) => (
        <div key={index} className="collapse collapse-plus border border-black hover:bg-slate-200">
          <input 
            type="radio" 
            name="my-accordion-3" 
            checked={index === selectedIndex} 
            onClick={() => handleSelectItem(index)}
            onChange={() => {}}
          />
          <div className="collapse-title text-xl font-medium">{item.q}</div>
          <div className="collapse-content">
            <p>{item.a}</p>
          </div>
        </div>
      ))}
      </div>
    </>
  );
};

export default Faq;
