import React, { useState, useContext, useEffect } from "react";
import {
  MdClose,
  MdMenu,
  MdAdd,
  MdSettings,
  MdLogout,
  MdLock,
  MdOutlineQuestionAnswer,

  // MdOutlineCoffee,
} from "react-icons/md";

import { LuArrowRightFromLine } from "react-icons/lu";

import { FaUser, FaEnvelope } from "react-icons/fa";
import { BiArrowFromLeft } from "react-icons/bi";

import { VscTriangleUp } from "react-icons/vsc";

import logo from "../assets/oliverLogo.png";
import logoInfogreffe from "../assets/logoOliver_beta3.png";
import logoInfogreffebeta from "../assets/logoOliver_infogreffe2_beta3.png";
import logoPlus from "../assets/oliverPlusLogo.png";
import logoPlusInfogreffe from "../assets/oliverPlusLogo_infogreffe.png";


import DarkMode from "./DarkMode";
import Modal from "./Modal";
import Setting from "./settings/Settings";
import AdminPanel from "./interface_admin/AdminPanel";
import Login from "./auth/Login";
import UserProfile from "./user_profile/UserProfile";
import Formalities from "./user_profile/Formalities"
import { AuthContext } from "../context/AuthContext";
import { useAppContext } from "../context/AppContext";
import { useColorsContext } from "../context/GlobalContext";

import Contact from "./Contact";
import Faq from "./Faq";
import Cgus from "./auth/Cgus";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import ConversationsList from "./ConversationsList";
import { createMessage, update_last_system_message_tokens, updateMessage } from "../utils/conversation";

/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBar = () => {
  const { subdomain } = useColorsContext();
  const [modalCgusOpen, setModalCgusOpen] = useState(false);
  const [cgus, setCgus] = useState("");



  const {
    compagnyInfos,
    setCompagnyInfos,
    clearChat,
    conversations,
    currentUser,
    isAdmin,
    version,
    setMessages,
    setCurrentConversationId,
    setWriting,
    newQuestion,
  } = useAppContext();

  const [open, setOpen] = useState(true);

  const [buttonsOpen, setButtonsOpen] = useState(true);
  const [modalSettingsOpen, setModalSettingsOpen] = useState(false);
  const [modalSettingsAdminOpen, setModalSettingsAdminOpen] = useState(false);
  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const [modalProfileOpen, setModalProfileOpen] = useState(false);
  const [modalContactOpen, setModalContactOpen] = useState(false);
  const [modalFaqOpen, setModalFaqOpen] = useState(false);

  const { handleLogout } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState('requêtes');

  const [convoId, setConvoId] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [modalConfiramtionDelete, setModalConfiramtionDelete] = useState(false);


  function formatNumberWithCommas(number) {
    var usFormat = number.toLocaleString("en-US");
    return usFormat.replaceAll(",", " ");
  }

  function handleResize() {
    window.innerWidth <= 720 ? setOpen(false) : setOpen(true);
  }

  const handleCompagnyInfos = (info, value) => {
    if (value === "")
      setCompagnyInfos((prevInfos) => ({ ...prevInfos, [info]: null }));
    else if (["effectif_moyen", "chiffre_affaires", "bilan"].includes(info)) {
      value = parseInt(value.replaceAll(" ", ""));
      if (value >= 0) {
        setCompagnyInfos((prevInfos) => ({
          ...prevInfos,
          [info]: formatNumberWithCommas(value),
        }));
      }
    } else {
      setCompagnyInfos((prevInfos) => ({ ...prevInfos, [info]: value }));
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const handleSelectConversation = async (conversationId) => {
    try {
      const conversationRef = doc(db, "users", currentUser.uid, "conversations", conversationId);
      const conversationSnap = await getDoc(conversationRef);

      if (conversationSnap.exists()) {
        const conversationData = conversationSnap.data();
        // setIsQuestionPersonnel(true);
        setCurrentConversationId(conversationId);
        setMessages(conversationData.messages);  // Met à jour l'état des messages avec les messages de la conversation sélectionnée
      } else {
        console.log("No conversation found!");
        setMessages([]);  // Réinitialise les messages si la conversation n'existe pas
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setMessages([]);  // Gère les erreurs en réinitialisant les messages
    }
  }

  const saveNewTitle = async (convoId) => {
    if (!newTitle.trim()) return; // Ne rien faire si le titre est vide
    const conversationRef = doc(db, "users", currentUser.uid, "conversations", convoId);
    try {
      await updateDoc(conversationRef, {
        titre: newTitle.trim()
      });
      console.log("Conversation title updated.");
      setEditingId(null); // Quitter le mode édition
    } catch (error) {
      console.error("Failed to update conversation title:", error);
    }
  };

  const promptRename = (convoId, currentTitle) => {
    setEditingId(convoId);
    setNewTitle(currentTitle); // Initialiser avec le titre actuel
  };

  const deleteConversation = async (conversationId) => {
    const conversationRef = doc(db, "users", currentUser.uid, "conversations", conversationId);
    try {
      await deleteDoc(conversationRef);
      console.log("Conversation deleted.");
      setModalConfiramtionDelete(false)
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };



  return (
    <div
      className={` ${open ? "md:min-w-[30%] md:w-[30%] md:relative absolute w-[90vw] z-10 " : "w-16"
        } sidebar `}
    >
      <div className="w-full h-full flex flex-col">
        <div className="sidebar__app-bar">
          <div className={`sidebar__app-logo ${!open && "scale-0 hidden"}`}>
            <span className="w-8 h-8">
              {subdomain == "infogreffe" ? (
                <>
                  {version == 1 ? (
                    <img src={logoPlus} alt="" className="w-40" />
                  ) : (
                    <img src={logoInfogreffebeta} alt="" className="w-32" />
                  )}
                </>

              ) : (
                <>
                  {version == 1 ? (
                    <img src={logoPlus} alt="" className="w-40" />
                  ) : (
                    <img src={logoInfogreffe} alt="" className="w-32" />
                  )}
                </>
              )}

            </span>
          </div>
          <div className={`sidebar__btn-close md:block hidden`} onClick={() => setOpen(!open)}>
            {open ? (
              <MdClose className="sidebar__btn-icon" />
            ) : (
              <MdMenu className="sidebar__btn-icon" />
            )}
          </div>

          <div className={`md:hidden ${open && "rotate-180 absolute right-0 mr-4"}`} onClick={() => setOpen(!open)}>
            {open ?
              <BiArrowFromLeft className="w-8 h-8 text-white" />
              :
              <BiArrowFromLeft className="w-8 h-8 text-white" />

            }
          </div>
        </div>

        <div className="flex w-full py-4">
          <div
            className={`${open ? "rounded-l-md" : "rounded-md justify-center"
              } border px-4 py-3 flex items-center gap-x-4
           cursor-pointer 
          hover:bg-light-white
          text-white text-base border-neutral-600 w-full`}
            onClick={newQuestion}
          >
            <div className="cursor-pointer duration-300 text-white text-xl">
              <MdAdd />
            </div>
            <h1
              className={`${!open && "hidden"
                } `}
            >
              Nouvelle question
            </h1>
          </div>
          <div
            onClick={() => setModalSettingsOpen(true)}
            className={`${!open && "hidden"
              } flex cursor-pointer hover:bg-light-white text-white
          px-4 border border-l-0
         rounded-r-md border-neutral-600 aspect-square items-center justify-center`}
          >
            <MdSettings />
          </div>
        </div>

        <>
          {console.log("conversations: ", conversations)}
          <div className="mt-2 overflow-y-auto flex-grow">
            {conversations.length >= 1 && (
              <>
                <h1 className="text-[#858585] ps-2 py-2 font-semibold"> Historique des questions </h1>
                <ConversationsList
                  conversations={conversations}
                  handleSelectConversation={handleSelectConversation}
                  editingId={editingId}
                  newTitle={newTitle}
                  setNewTitle={setNewTitle}
                  saveNewTitle={saveNewTitle}
                  promptRename={promptRename}
                  setModalConfiramtionDelete={setModalConfiramtionDelete}
                  setConvoId={setConvoId}
                />
              </>
            )}
          </div>

        </>



        <div
          className={
            "nav__bottom border-t-[1px] border-neutral-600 relative " +
            (buttonsOpen || !open ? "flex-col" : "") +
            " "
          }
        >
          <div
            className="w-full flex justify-center absolute top-[-8px]"
            onClick={() => {
              setButtonsOpen(!buttonsOpen);
            }}
          >
            <div
              className={
                "w-12 h-4 border bg-dark-grey border-neutral-600  flex items-center justify-center cursor-pointer " +
                (!open ? "hidden" : "")
              }
            >
              <VscTriangleUp
                className={`text-neutral-600 ${buttonsOpen ? "rotate-180" : ""}`}
              />
            </div>
          </div>
          <DarkMode open={open && buttonsOpen} />
          <div onClick={() => setModalContactOpen(!modalContactOpen)} className="nav">
            <span
              htmlFor="setting-modal"
              className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
            >
              <div className="nav__icons">
                <FaEnvelope />
              </div>
              <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>
                Contact
              </h1>
            </span>
          </div>
          <div onClick={() => setModalFaqOpen(!modalFaqOpen)} className="nav">
            <span
              htmlFor="setting-modal"
              className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
            >
              <div className="nav__icons">
                <MdOutlineQuestionAnswer />
              </div>
              <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>FAQ</h1>
            </span>
          </div>

          {isAdmin ? (
            <div onClick={() => setModalSettingsAdminOpen(true)} className="nav">
              <span
                htmlFor="setting-modal"
                className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
              >
                <div className="nav__icons">
                  <MdLock />
                </div>
                <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>
                  Paramètres Administrateur
                </h1>
              </span>
            </div>
          ) : null}

          {currentUser ? (
            <div onClick={() => setModalProfileOpen(true)} className="nav">
              <span
                htmlFor="setting-modal"
                className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
              >
                <div className="nav__icons">
                  <FaUser />
                </div>
                <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>
                  Mon compte
                </h1>
              </span>
            </div>
          ) : null}

          {currentUser ? (
            <div className="nav">
              <div
                onClick={handleLogout}
                className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
              >
                <div className="nav__icons">
                  <MdLogout />
                </div>
                <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>
                  Se déconnecter
                </h1>
              </div>
            </div>
          ) : (
            <div onClick={() => setModalLoginOpen(true)} className="nav">
              <span
                htmlFor="setting-modal"
                className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
              >
                <div className="nav__icons">
                  <MdLock />
                </div>
                <h1 className={`${(!open || !buttonsOpen) && "hidden"}`}>
                  Se connecter
                </h1>
              </span>
            </div>
          )}

          <div className={`${(!open || !buttonsOpen) ? "hidden" : "flex w-full mt-auto justify-around items-center text-gray-400 z-10"} space-x-4`}>

            <div className="text-center">
              <a onClick={() => { setModalCgusOpen(true); setCgus("Mentions légales"); }} className="cursor-pointer text-xs whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
                Mentions légales
              </a>
            </div>
            <div className="text-center">
              <a onClick={() => { setModalCgusOpen(true); setCgus("CGU"); }} className="cursor-pointer text-xs whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
                CGVU
              </a>
            </div>
            <div className="text-center">
              <a onClick={() => { setModalCgusOpen(true); setCgus("Politique de confidentialité"); }} className="cursor-pointer text-xs whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
                Politique de confidentialité
              </a>
            </div>
          </div>


        </div >
      </div>
      <Modal
        title="Paramètres"
        modalOpen={modalSettingsOpen}
        setModalOpen={setModalSettingsOpen}
        modalSize={"medium"}
      >

        <Setting
          setModalOpen={setModalSettingsOpen}
        />
      </Modal>

      <Modal
        title={cgus}
        modalOpen={modalCgusOpen}
        setModalOpen={setModalCgusOpen}
        modalSize={"cgus"}
      >
        <Cgus setCgus={setCgus} content={cgus} />
      </Modal>
      {
        isAdmin && (
          <Modal
            title="Paramètres Administrateur"
            modalOpen={modalSettingsAdminOpen}
            setModalOpen={setModalSettingsAdminOpen}
            modalSize={"large"}
          >
            <AdminPanel />
          </Modal>
        )
      }

      <Modal
        modalOpen={modalLoginOpen}
        setModalOpen={setModalLoginOpen}
        modalSize={"auto"}
      >
        <Login setModalLoginOpen={setModalLoginOpen} />
      </Modal>
      <Modal
        title={"Mon compte"}
        modalOpen={modalProfileOpen}
        setModalOpen={setModalProfileOpen}
        modalSize={"large"}
        titleIcon={<FaUser className="mr-2" />}
      >
        <div className="flex justify-center space-x-4 my-4"> {/* Centre les boutons et ajoute un espace entre eux */}
          <button
            className={`px-6 py-2 ${activeTab === 'requêtes' ? 'bg-gray-500 text-white' : 'bg-white text-gray-500'} border border-gray-500`}
            onClick={() => setActiveTab('requêtes')}
          >
            Mes requêtes
          </button>

          <button
            className={`px-6 py-2 ${activeTab === 'formalités' ? 'bg-gray-500 text-white' : 'bg-white text-gray-500'} border border-gray-500`}
            onClick={() => setActiveTab('formalités')}
          >
            Mes formalités
          </button>
        </div>

        {activeTab === 'requêtes' ? (
          <UserProfile setOpen={setModalProfileOpen} />
        ) : (
          <Formalities />
        )}
      </Modal>

      <Modal
        title=""
        modalOpen={modalConfiramtionDelete}
        setModalOpen={setModalConfiramtionDelete}
        modalSize={"auto"}
      >
        <div className="flex flex-col items-center justify-center p-4">
          {/* <img src={`${subDomains.includes(subdomain) ? logoMesFormalitesClair : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" /> */}

          <p className="text-center mb-6"> Etes-vous sûr de vouloir supprimer cette  conversation ?</p>
          <div className='flex justify-between gap-4'>

            <button
              onClick={() => {
                setModalConfiramtionDelete(false)
              }}
              className="signupFormContainerContinuer text-white  font-bold"
            >
              Annuler
            </button>
            <button
              onClick={() => {
                deleteConversation(convoId)
              }}
              className="signupFormContainerContinuer bg-red-500 text-white font-bold"
            >
              Supprimer
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title={"Contact"}
        modalOpen={modalContactOpen}
        setModalOpen={setModalContactOpen}
        modalSize={"auto"}
        titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
      >
        <Contact setOpen={setModalContactOpen} />
      </Modal>

      <Modal
        title={"FAQ"}
        modalOpen={modalFaqOpen}
        setModalOpen={setModalFaqOpen}
        modalSize={"auto"}
        titleIcon={<MdOutlineQuestionAnswer className="mr-2 text-[1.3em]" />}
      >
        <Faq />
      </Modal>
    </div >
  );
};

export default SideBar;
