import React, { useState, useEffect } from 'react';
import Modal from "../../Modal";
import logoInfogreffe from "../../../assets/logoOliver_beta3.png";
import Cgus from "../../../components/auth/Cgus";
import { fetchData } from "../../../utils/ask_api";
import { useAppContext } from "../../../context/AppContext";
import ContactInformationForm from "../../../components/chat_view/chat_message/render_step_content_reservation/ContactInformations"
import ValidationForm from "../../../components/chat_view/chat_message/render_step_content_reservation/ValidattionForm"
import ConfirmationOrder from "../../../components/chat_view/chat_message/render_step_content_reservation/ConfirmationOrder"
import PaymentForm from "../../../components/chat_view/chat_message/render_step_content_reservation/PaymentForm"
import { db } from "../../../firebase.config";
import { doc, getDoc } from "firebase/firestore";

const StepIndicator = ({ currentStep }) => {
  return (
    <div className="flex justify-center space-x-4 my-4">
      {Array.from({ length: 4 }, (_, i) => i + 1).map((step) => (
        <div
          key={step}
          className={`h-2 w-2 rounded-full ${currentStep === step ? 'bg-gray-500' : 'bg-gray-300 border-0'
            }`}
        />
      ))}
    </div>
  );
};

const ReservationModal = ({
  modalReservationOpen,
  setModalReservationOpen,
  categoryFormalitie,
  titleFormalitie,
}) => {

  const { currentUser } = useAppContext();
  const nomPrenon = currentUser?.displayName.split(" ") || ""
  const [companyName, setCompanyName] = useState("");
  const [sirenNumber, setSirenNumber] = useState("");
  const [tel, setTel] = useState("");
  const [firstname, setFirstName] = useState(nomPrenon[0] || "");
  const [lastName, setLastName] = useState(nomPrenon[1] || "");
  const [email, setEmail] = useState(currentUser?.email || "");

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isSirenNumberValid, setIsSirenNumberValid] = useState(true);
  const [isTelValid, setIsTelValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptCgus, setAcceptCgus] = useState(false);

  const [modalCguOpen, setModalCguOpen] = useState("");
  const [modalTile, setModalTitle] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);



  // ConfirmationOder : 

  // États pour stocker les données de formalitées recu de firebase
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [packGlobal, setPackGlobal] = useState(false);
  const [prixHT, setPrixHT] = useState(0);
  const [piecesRequises, setPiecesRequises] = useState([]);
  const [autreFrais, setAutreFrais] = useState([]);
  const [fraisAnnonceLegale, setFraisAnnonceLegale] = useState(0);
  const [fraisGreffe, setFraisGreffe] = useState(0);
  const [fraisRBE, setFraisRBE] = useState(0);
  const [honorairesAccompagnement, setHonorairesAccompagnement] = useState(0);


  useEffect(() => {
    if (currentUser?.displayName) {
      const parts = currentUser.displayName.split(" ");
      if (parts.length > 0) {
        setFirstName(parts[0]);
        setLastName(parts[parts.length - 1]);
      }
    } else {
      setFirstName('');
      setLastName('');
    }

    setEmail(currentUser?.email || '');
    const phoneNumber = currentUser?.providerData?.[0]?.phoneNumber;
    if (phoneNumber) {
      setTel(phoneNumber);
    } else {
      setTel('');
    }
  }, [currentUser]); // Dépendance à currentUser pour réagir à ses changements


  useEffect(() => {
    // Crée une fonction interne asynchrone pour effectuer des appels asynchrones
    const fetchData = async () => {
      try {
        const docRef = doc(db, `annuaire_formalites_${categoryFormalitie}`, titleFormalitie);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log(data);
          setPackGlobal(data.packGlobal || false);
          setPrixHT(data.prixHT || 0);
          setPiecesRequises(data.piecesRequises || []);
          setAutreFrais(data.autreFrais || []);
          setFraisAnnonceLegale(data.fraisAnnonceLegale || 0);
          setFraisGreffe(data.fraisGreffe || 0);
          setFraisRBE(data.fraisRBE || 0);
          setHonorairesAccompagnement(data.honorairesAccompagnement || 0);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchData();
  }, [categoryFormalitie, titleFormalitie]);

  const tauxTVA = 0.20;

  // Calcul du montant de la TVA
  const calculTVA = (montantHT) => montantHT * tauxTVA;

  // Calcul du total TTC
  const calculTotalTTC = (montantHT) => montantHT + calculTVA(montantHT);

  const montantHT = packGlobal ? prixHT : fraisGreffe + fraisRBE + honorairesAccompagnement + fraisAnnonceLegale;

  const montantTVA = calculTVA(montantHT);

  const totalTTC = calculTotalTTC(montantHT);

  console.log("=====> totalAmount", totalTTC);
  useEffect(() => {
    setBillingInformation((prevInfo) => ({
      ...prevInfo,
      totalAmount: totalTTC,
    }));
  }, [totalTTC]);

  // états pour les information de payement : 

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [billingInformation, setBillingInformation] = useState({
    prenom: firstname,
    nom: lastName,
    email: email,
    tel: tel,
    companyName: companyName,
    address: '',
    totalAmount: totalTTC,
    serviceDescription: `Formalité de ${Capitalize(categoryFormalitie)} : ${Capitalize(titleFormalitie)}`,
  });

  const [requiredDocuments, setRequiredDocuments] = useState(piecesRequises);
  console.log('billingInformation:', billingInformation);
  console.log('requiredDocuments:', requiredDocuments);

  useEffect(() => {
    setRequiredDocuments(piecesRequises);
  }, [piecesRequises]);


  const [validations, setValidations] = useState({
    isFirstNameValid: isFirstNameValid,
    isLastNameValid: isLastNameValid,
    isEmailValid: isEmailValid,
    isTelValid: isTelValid,
    isAddressValid: false,
  });
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };


  const updateBillingInfo = (newBillingInfo) => {
    setBillingInformation(prev => ({ ...prev, ...newBillingInfo }));

    if (newBillingInfo.hasOwnProperty('prenom')) {
      setValidations(prev => ({ ...prev, isFirstNameValid: newBillingInfo.prenom.trim().length > 0 }));
    }
    else if (newBillingInfo.hasOwnProperty('nom')) {
      setValidations(prev => ({ ...prev, isLastNameValid: newBillingInfo.nom.trim().length > 0 }));
    }
    else if (newBillingInfo.hasOwnProperty('email')) {
      setValidations(prev => ({ ...prev, isEmailValid: validateEmail(newBillingInfo.email) }));
    }
    else if (newBillingInfo.hasOwnProperty('tel')) {
      setValidations(prev => ({ ...prev, isTelValid: /^[0-9+\s()-]+$/.test(newBillingInfo.tel.trim()) }));
    }
    else if (newBillingInfo.hasOwnProperty('address')) {
      setValidations(prev => ({ ...prev, isAddressValid: newBillingInfo.address.trim().length > 0 }));
    } else if (newBillingInfo.hasOwnProperty('companyName')) {
      setValidations(prev => ({ ...prev, isCompanyNameValid: newBillingInfo.companyName.trim().length >= 0 }));
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < 4 ? prevStep + 1 : prevStep));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (

          <ContactInformationForm
            companyName={companyName}
            sirenNumber={sirenNumber}
            tel={tel}
            categoryFormalitie={categoryFormalitie}
            titleFormalitie={titleFormalitie}
            prenom={firstname}
            nom={lastName}
            email={email}
            isCompanyNameValid={isCompanyNameValid}
            isSirenNumberValid={isSirenNumberValid}
            isFirstNameValid={isFirstNameValid}
            isLastNameValid={isLastNameValid}
            isEmailValid={isEmailValid}
            isTelValid={isTelValid}
            attemptedSubmit={attemptedSubmit}

            handleCompanyNameChange={handleCompanyNameChange}
            handleNextStepClick={handleNextStepClick}
            handleSirenNumberChange={handleSirenNumberChange}
            handleFirstNameChange={handleFirstNameChange}
            handleLastNameChange={handleLastNameChange}
            handleEmailChange={handleEmailChange}
            handleTelChange={handleTelChange}
            setAcceptTerms={setAcceptTerms}
            setAcceptCgus={setAcceptCgus}
            Capitalize={Capitalize}
            nextStep={nextStep}
          />
        );
      case 2:
        return <ValidationForm
          categoryFormalitie={categoryFormalitie}
          titleFormalitie={titleFormalitie}
          Capitalize={Capitalize}
          nextStep={nextStep}
        />;
      case 3:
        return <ConfirmationOrder
          categoryFormalitie={categoryFormalitie}
          titleFormalitie={titleFormalitie}
          Capitalize={Capitalize}
          nextStep={nextStep}
          packGlobal={packGlobal}
          prixHT={prixHT}
          honorairesAccompagnement={honorairesAccompagnement}
          fraisRBE={fraisRBE}
          fraisAnnonceLegale={fraisAnnonceLegale}
          fraisGreffe={fraisGreffe}
          montantTVA={montantTVA}
          totalTTC={totalTTC}
        />;
      case 4:
        return <PaymentForm
          categoryFormalitie={categoryFormalitie}
          titleFormalitie={titleFormalitie}
          step={currentStep}
          acceptTerms={acceptTerms}
          acceptCgus={acceptCgus}
          Capitalize={Capitalize}
          setAcceptTerms={setAcceptTerms}
          setAcceptCgus={setAcceptCgus}
          setModalTitle={setModalTitle}
          setModalCguOpen={setModalCguOpen}
          handleSubmitFormalitie={handleSubmitFormalitie}
          handleInputChange={handleInputChange}
          billingInformation={billingInformation}
          validations={validations}
          piecesRequises={piecesRequises}

        />;
      default:
        return null;
    }
  };


  // Update event handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateBillingInfo({ [name]: value });
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
    handleInputChange(e);
  };

  const handleFirstNameChange = (e) => {
    const firstNameValue = e.target.value.trim();
    setFirstName(firstNameValue);
    setIsFirstNameValid(firstNameValue.length > 0);
    handleInputChange(e);
  };

  const handleLastNameChange = (e) => {
    const lastNameValue = e.target.value.trim();
    setLastName(lastNameValue);
    setIsLastNameValid(lastNameValue.length > 0);
    handleInputChange(e);
  };

  const handleCompanyNameChange = (e) => {
    const companyNameValue = e.target.value.trim();
    setCompanyName(companyNameValue);
    setIsCompanyNameValid(companyNameValue.length > 0);
    handleInputChange(e);
  };

  const handleSirenNumberChange = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{9}$/;
    const isValid = regex.test(value);
    setIsSirenNumberValid(isValid);
    setSirenNumber(value);
  };

  const handleTelChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9+\s()-]+$/;
    const isValid = regex.test(value);
    console.log(isValid)
    setIsTelValid(isValid);
    setTel(value);
    handleInputChange(e);
  };

  const handleSubmitFormalitie = async (billingInformation, piecesRequises) => {
    console.log("handleSubmitFormalitie");
    try {
      // const nomPrenon = currentUser.displayName.split(" ")
      const formalitieData = {
        dateReservation: new Date().toISOString(),
        categorie: categoryFormalitie,
        nomEntreprise: companyName,
        siret: sirenNumber,
        prenom: firstname,
        nom: lastName,
        email: currentUser.email,
        email_contact: email,
        telephone: tel,
        titreFormalitie: titleFormalitie,
        montantHT: montantHT,
        dateFinalisation: null,
        billing_information: billingInformation,
        required_documents: piecesRequises
      };
      console.log("fetch formalitieData:", formalitieData)
      const d = await fetchData("create_formalites", formalitieData, "POST");
      console.log(d);
      resetForm();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  const resetForm = () => {
    setCompanyName("");
    setSirenNumber("");
    setTel("")

    setIsCompanyNameValid(true);
    setIsSirenNumberValid(true);
    setIsTelValid(true)

    setAcceptCgus(false);
    setAcceptTerms(false);

    setModalOpen(true);

    setModalReservationOpen(false);
  };

  const handleNextStepClick = () => {
    setAttemptedSubmit(true); // Indique qu'une tentative de soumission a eu lieu
    // Vérifie si toutes les conditions de validité sont remplies
    console.log("handleNextStepClick: ", isTelValid)
    if (
      companyName &&
      (categoryFormalitie === "creation" || sirenNumber) &&
      firstname && lastName &&
      email && tel &&
      isCompanyNameValid &&
      (categoryFormalitie === "creation" || isSirenNumberValid) &&
      isFirstNameValid && isLastNameValid &&
      isEmailValid && isTelValid
    ) {
      nextStep(); // Tout est valide, passe à l'étape suivante
    } else {
      // Sinon, vérifie chaque condition et focalise sur le premier champ invalide
      console.log("isTelValid: ", isTelValid)
      console.log(`Tel: '${tel}'`,)
      console.log("isCompanyNameValid: ", isCompanyNameValid)
      console.log("companyName: ", companyName)
      if (!companyName) {
        document.getElementById('companyName').focus();
      } else if (categoryFormalitie !== "creation" && (!sirenNumber)) {
        document.getElementById('sirenNumber').focus();
      } else if (!firstname) {
        document.getElementById('prenom').focus();
      } else if (!lastName) {
        document.getElementById('nom').focus();
      } else if (!email) {
        document.getElementById('email').focus();
      } else if (!tel) {
        document.getElementById('tel').focus();
      }
    }
  };



  return (
    <>
      <Modal
        modalOpen={modalReservationOpen}
        setModalOpen={setModalReservationOpen}
        title=""
        modalSize={"cgus"}
        back={currentStep !== 1}
        prevStep={prevStep}
      >
        <div className={`pb-8 w-full`} >
          <div className="md:px-24">
            <div className="signupFormContainer w-ful">
              <img src={logoInfogreffe} alt="logoInfogreffe" className="w-40 mb-6" />
              <div
                className="signupFormContainerForm loginContainerContent w-full"
              >
                {renderStepContent(currentStep)}
                <StepIndicator currentStep={currentStep} />

              </div>
            </div>
          </div>

        </div>
      </Modal>

      <Modal title={modalTile} modalOpen={modalCguOpen} setModalOpen={setModalCguOpen} modalSize={"cgus"}>
        <Cgus content={modalTile}></Cgus>
      </Modal>

      <Modal
        title="Message"
        modalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        modalSize={"auto"}
      >
        <div className="flex flex-col items-center justify-center p-4">
          <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" />
          <p className="text-center mb-6"> Votre paiement est confirmé et la facture a été envoyée par e-mail. Rendez-vous dans 'Mon Compte - Formalités' pour uploader vos documents.</p>
          <p className="text-center mb-6">  Merci pour votre confiance !</p>
          <button
            onClick={() => setModalOpen(false)}
            className="signupFormContainerContinuer text-white font-bold"
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ReservationModal;