import React, { useState, useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { db } from "../../firebase.config";
import { onSnapshot, doc } from "firebase/firestore";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ViewUsage = () => {
  const { currentUser } = useAppContext();
  const [usageData, setUsageData] = useState({ monthly: [], daily: [] });
  const [currentView, setCurrentView] = useState("monthly"); // Added state for current view
  const [selectedModel, setSelectedModel] = useState('gpt_4_turbo');

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = onSnapshot(
        doc(db, `requests/${currentUser.uid}`),
        (doc) => {
          const data = doc.data();
          // console.log("ViewUsage: ", data);
          if (data) {
            const monthlyData = processUsageData(data, "month");
            const dailyData = processUsageData(data, "day");
            setUsageData({ monthly: monthlyData, daily: dailyData });
          } else {
            setUsageData({ monthly: [], daily: [] });
          }
        }
      );

      return () => unsubscribe();
    }
  }, [currentUser, selectedModel]);

  // Helper function to process data
  const processUsageData = (data, type) => {
    const result = [];
    const currentDate = new Date();
    const limitDate = new Date();
    const modelInfo = models[selectedModel];

    if (type === "month") {
      limitDate.setMonth(currentDate.getMonth() - 12);
    } else {
      limitDate.setDate(currentDate.getDate() - 30);
    }

    Object.values(data).forEach((item) => {
      if (item.model_info && item.model_info.name === modelInfo) {
        const date = item.timestamp.toDate();
        if (date >= limitDate) {
          const label = formatDateLabel(date, type);
          const existingData = result.find((d) => d.label === label);
          if (existingData) {
            existingData.value++;
          } else {
            result.push({ label, value: 1 });
          }
        }
      }
    });

    return fillMissingTimePeriods(result, type, limitDate);
  };

  // Format date label based on type
  const formatDateLabel = (date, type) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (type === "month") {
      return `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
    }
    return `${date.getDate().toString().padStart(2, "0")}-${monthNames[date.getMonth()]
      }`;
  };

  // Fill missing time periods
  const fillMissingTimePeriods = (data, type, limitDate) => {
    const result = [...data];
    const currentDate = new Date();

    while (currentDate > limitDate) {
      const label = formatDateLabel(currentDate, type);
      if (!result.some((item) => item.label === label)) {
        result.push({ label, value: 0 });
      }
      if (type === "month") {
        currentDate.setMonth(currentDate.getMonth() - 1);
      } else {
        currentDate.setDate(currentDate.getDate() - 1);
      }
    }

    return result.sort((a, b) => new Date(a.label) - new Date(b.label));
  };

  // Chart data function
  const chartData = (data) => ({
    labels: data.map((item) => item.label),
    datasets: [
      {
        label: "Requêtes",
        data: data.map((item) => item.value),
        backgroundColor: "rgba(0, 123, 255, 0.5)",
        borderColor: "rgba(0, 123, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  // Chart options
  const optionsChart = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        offset: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          stepSize: 10,
        },
      },
    },
    elements: {
      line: {
        tension: 0.3,
        fill: "start",
      },
      point: {
        radius: 0,
      },
    },
  };

  const switchView = () => {
    setCurrentView(currentView === "monthly" ? "daily" : "monthly");
  };

  const calculateTotalRequests = (data) => {
    return data.reduce((total, current) => total + current.value, 0);
  };

  const models = {
    open_mixtral_8x7b: "Open Mixtral 8x7b",
    mistral_medium: "Mistral medium",
    gpt_3_5: "GPT 3.5",
    gpt_4_turbo: "GPT 4 turbo"
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-between w-full items-center">

        {/* <div className=""><span className="font-bold underline">Historique des requêtes</span> (modèle GPT-4)</div> */}
        <div className="">
          <span className="font-bold underline">Historique des requêtes</span> modèle: <select
            onChange={handleModelChange}
            value={selectedModel}
            className="ml-2 border rounded p-1 text-xs"
          >
            {Object.entries(models).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-col items-end">
          <button
            onClick={switchView}
            className="border border-blue-logo-color text-blue-logo-color font-semibold rounded border-2 p-1 text-xs "
          >
            Usage{" "}
            {currentView === "monthly"
              ? "sur les 30 derniers jours"
              : "sur l'année"}
          </button>
          <div className="text-xs text-blue-logo-color pt-2">Requêtes sur la période :  {calculateTotalRequests(usageData[currentView])}</div>
        </div>
      </div>
      <div style={{ height: "200px" }}>
        <Bar options={optionsChart} data={chartData(usageData[currentView])} />
      </div>
    </div>
  );
};

export default ViewUsage;
