import React from "react";
import { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { GoDotFill, GoDot } from "react-icons/go";
import { FaEnvelope } from "react-icons/fa";
import logoInfogreffe from "../../../assets/logoOliver_beta3.png";
import moment from "moment";
import "moment/locale/fr";
import Document from "./Document";
import { useAppContext } from "../../../context/AppContext.js";
import ReservationSection from "./ReservationSection"

import light from "../../../assets/light.png";
import InfosInput from "../../anpi/InfosInput.jsx";
import { updateMessage, update_last_system_message_tokens, createMessage } from '../../../utils/conversation';

import { db } from "../../../firebase.config";
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";

import Modal from "../../Modal";
import Evaluation from "../../Evaluation.jsx";
import { FaRegStar, FaStar } from "react-icons/fa";
import Contact from "../../Contact.jsx";
import { fetchData } from "../../../utils/ask_api.js";


/**
 * Composant AssistantMessage affiche les messages générés par le système/assistant.
 * Il peut traiter différents types de contenu, y compris les formalités d'entreprise.
 *
 * @param {Object} props Les props du composant
 * @param {number} props.message_id Identifiant unique du message
 * @param {number} props.index Index du message dans la liste des messages
 * @param {Array} props.documents Documents liés au message, si applicable
 * @param {string} props.content Contenu du message
 * @param {Date} props.createdAt Date de création du message
 * @param {Object} props.metadatas Métadonnées supplémentaires liées au message
 * @param {boolean} props.writing Indique si un message est en cours de rédaction
 * @param {Function} props.setVersionsOpen Handler pour ouvrir les versions du chat
 */
const AssistantMessage = ({
  message_id,
  index,
  documents,
  content,
  createdAt,
  metadatas,
  writing,

  setVersionsOpen,
}) => {
  const {
    messages,
    selectedOption,
    streamError,
    version,
    nameModel,
    model,

    setSelectedOption,
    setWriting,
    setMessages,
    retryMessage,
  } = useAppContext();

  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const [modalReservationOpen, setModalReservationOpen] = useState(false);
  const [modalEvaluationOpen, setModalEvaluationOpen] = useState(false);
  const [modalContactOpen, setModalContactOpen] = useState(false);
  const [showArticles, setShowArticles] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [starRating, setStarRating] = useState(0);
  const [oldStarRating, setOldStarRating] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(null);
  const [displayOptionsFormalite, setDisplayOptionsFormalite] = useState(true);

  // Crée un contexte pour gérer les niveaux de liste dans le markdown.
  const ListLevelContext = React.createContext(0);

  // Composant personnalisé pour rendre les éléments de liste en tenant compte de leur niveau d'indentation.
  const LiRenderer = ({ children }) => {
    const level = React.useContext(ListLevelContext);

    return (
      <li className="flex hover:underline-offset-4">
        <div className="mt-[0.25em] mr-[2px] flex">
          {level > 0 ? (
            <GoDotFill className="w-3" />
          ) : (
            <GoDot className="w-3" />
          )}
        </div>
        <div>{children}</div>
      </li>
    );
  };

  // Composant personnalisé pour rendre les listes non ordonnées avec un style spécifique en fonction de leur niveau.
  const UlRenderer = ({ children }) => {
    const level = React.useContext(ListLevelContext);
    const nextLevel = level + 1;
    const className = level === 0 ? "space-y-[1px] list-none hover:list-disc" : "nested-ul-style";
    return (
      <ListLevelContext.Provider value={nextLevel}>
        <ul className={className}>{children}</ul>
      </ListLevelContext.Provider>
    );
  };

  async function getAllDocumentIdsFromCategory(category) {
    const collectionRef = collection(db, `annuaire_formalites_${category}`);
    try {
      const snapshot = await getDocs(collectionRef);
      const documentIds = snapshot.docs.map(doc => doc.id);
      return documentIds;
    } catch (error) {
      console.error(`Erreur lors de la récupération des IDs des documents pour ${category}:`, error);
    }
  }

  async function getDocumentsIdsModificationByTag(tag) {
    const collectionRef = collection(db, `annuaire_formalites_modification`);
    const queryRef = query(collectionRef, where("tag", "==", tag));

    try {
      const snapshot = await getDocs(queryRef);
      const documents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return documents;
    } catch (error) {
      console.error("Erreur lors de la récupération des documents par tag:", error);
      throw new Error(error);
    }
  }

  async function get_text_by_category_and_titleFormalite(category, titreFormalite) {
    try {
      const docRef = doc(db, `annuaire_formalites_${category}`, titreFormalite);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().description;
      } else {
        return "Texte non trouvé pour la combinaison spécifiée.";
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      return "Erreur lors de la récupération des données.";
    }
  }

  function formatTextToMarkdown(text) {
    // Remplacer les espaces multiples par un seul espace
    let formattedText = text.replace(/\s+/g, ' ');

    // Insérer un saut de ligne Markdown avant chaque élément de liste
    formattedText = formattedText.replace(/- /g, '\n\n- ');

    // Insérer un saut de ligne Markdown avant chaque élément de liste numérotée
    formattedText = formattedText.replace(/(\d+)\. /g, '\n\n$1. ');

    // Insérer un saut de ligne Markdown avant chaque titre
    formattedText = formattedText.replace(/Liste des pièces requises/g, '\n\n## Liste des pièces requises');
    formattedText = formattedText.replace(/Pièces à télécharger :/g, '\n\n## Pièces à télécharger :');

    // Ajouter des sauts de ligne supplémentaires pour séparer les paragraphes
    formattedText = formattedText.replace(/(\.)([A-Z])/g, '$1\n\n$2');

    return formattedText;
  }

  const handleClick = (href, children) => {
    setWriting(true);
    const newMsg = createMessage(children, "user", undefined, {}, 0);
    updateMessage(newMsg, setMessages);
    get_text_by_category_and_titleFormalite(selectedOption.action, children)
      .then(markdownContent => {
        console.log(markdownContent)
        markdownContent = formatTextToMarkdown(markdownContent);
        console.log("After\n", markdownContent)
        const words = markdownContent.split(" ");
        const newMsgSystem = createMessage("", "system", undefined, {}, -4);
        updateMessage(newMsgSystem, setMessages);

        (async () => {
          for (const word of words) {
            const randomTimeout = 5;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            if (!writing) {
              setWriting(true);
            }
            // Met à jour le message système avec le mot actuel
            update_last_system_message_tokens(`${word} `, setMessages);
          }
          setWriting(false);
        })();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération du contenu :", error);
        // Gérer l'erreur, par exemple, en affichant un message d'erreur à l'utilisateur
      });
  };

  // Définit des renderers personnalisés pour ReactMarkdown afin de personnaliser l'affichage du markdown.
  const md_renderers = {
    // Define a custom renderer for links
    table: ({ children }) => (
      <div className="overflow-x-auto overflow-y-hidden w-full relative">
        <table className="table">{children}</table>
      </div>
    ),
    a: ({ node, ...props }) => {
      if (selectedOption && ['creation', 'modification', 'cessation', 'secretariat'].includes(selectedOption.action)) {
        return <a className="underline font-semibold break-all" {...props} onClick={(e) => {
          e.preventDefault();
          setSelectedOption({ ...selectedOption, titleFormalite: props.children });
          handleClick(props.href, props.children)
        }} />
      }
      return <a className="underline font-semibold break-all" {...props} target="_blank" />
    }
    ,
    ol: ({ children }) => <ol className="space-y-1">{children}</ol>,
    ul: UlRenderer,
    li: LiRenderer,
    thead: ({ children }) => <thead className="text-inherit	">{children}</thead>,
    tr: ({ children }) => <tr className=" border-gray-400">{children}</tr>,
    // link: (props) => <CustomLink {...props} onClick={handleLinkClick} />
  };

  // Permet d'afficher ou de cacher les documents/articles liés au message.
  const toggleArticles = () => {
    setShowArticles((prevShowArticles) => !prevShowArticles);
  };

  // // Réinitialise l'affichage des filtres si aucune option n'est sélectionnée.
  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption({ ...selectedOption, displayOptionsFormalite: true });
    }
  }, [selectedOption, setSelectedOption]);

  useEffect(() => {
    if (modalEvaluationOpen === false && starRating <= 2) {
      console.log("useEffect oldStarRating: ", oldStarRating);

      setStarRating(oldStarRating); setHoveredStar(null);
    };

  }, [modalEvaluationOpen, oldStarRating])


  const option_formalités_d_entreprise = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    const sentence = "Que souhaitez-vous faire ?".split(" ");

    const printWordByWord = async () => {
      for (const word of sentence) {
        // Generate a random timeout between 1 and 5 seconds (in milliseconds)
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }

  const option_effectuer_formalites_d_entreprise = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);
    const sentence = "Quels type de formalité souhaitez-vous réaliser ?".split(" ");
    const printWordByWord = async () => {
      for (const word of sentence) {
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }

  const normalizeString = (str) => str.trim().replace(/^ +/gm, '');


  const option_creation_formalites = () => {
    setWriting(true);
    const newMsgSystem = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsgSystem, setMessages);

    getAllDocumentIdsFromCategory("creation")
      .then(documentIds => {
        const markdownLinks = documentIds.map(id =>
          `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
        ).join('\n');

        const markdownContent = `
          Vous souhaitez immatriculer une :\n\n${markdownLinks}
        `.split(" ");

        const printWordByWord = async () => {
          for (const word of markdownContent) {
            const randomTimeout = Math.floor(Math.random() * 5) + 20;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            update_last_system_message_tokens(`${word} `, setMessages);
            setWriting(false);
          }
        };
        printWordByWord();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des IDs des documents :", error);
      });
  };

  const option_modification_formalites = () => {
    setWriting(true);
    const newMsgSystem = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsgSystem, setMessages);

    getDocumentsIdsModificationByTag("statutaires")
      .then(statutaires => {
        const markdownLinksStatutaires = statutaires.map(doc =>
          `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
        ).join('\n');

        return getDocumentsIdsModificationByTag("autres")
          .then(autres => {
            const markdownLinksAutres = autres.map(doc =>
              `- [${doc.id.replace(/_/g, ' ')}](#formalite/${encodeURIComponent(doc.id)})`
            ).join('\n');

            const markdownContent = `
            Veuillez cliquer sur la formalité de modification que vous souhaitez effectuer :
            
            **Modifications statutaires :**
            ${markdownLinksStatutaires}
            
            **Autres modifications :**
            ${markdownLinksAutres}
            `.split(" ")

            const printWordByWord = async () => {
              for (const word of markdownContent) {
                const randomTimeout = Math.floor(Math.random() * 5) + 20;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
              }
              setWriting(false);
            };
            printWordByWord();
          });
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des documents:", error);
        setWriting(false);
      });
  };

  const option_cessation_formalites = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    getAllDocumentIdsFromCategory("cessation")
      .then(documentIds => {
        const markdownLinks = documentIds.map(id =>
          `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
        ).join('\n');

        const markdownContent = `
      Veuillez cliquer sur la formalité de cessation que vous souhaitez réaliser :\n\n${markdownLinks}
      `.split(" ");

        const printWordByWord = async () => {
          for (const word of markdownContent) {
            const randomTimeout = Math.floor(Math.random() * 5) + 20;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            update_last_system_message_tokens(`${word} `, setMessages);
            setWriting(false);
          }
        };
        printWordByWord();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des IDs des documents :", error);
      });
  }

  const option_secretariat_formalites = () => {
    setWriting(true);
    const newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    getAllDocumentIdsFromCategory("secretariat")
      .then(documentIds => {
        const markdownLinks = documentIds.map(id =>
          `- [${id.replace(/ /g, ' ')}](#formalite/${encodeURIComponent(id)})`
        ).join('\n');

        const markdownContent = `
      Veuillez cliquer sur la formalité de secrétariat juridique que vous souhaitez effectuer :\n\n${markdownLinks}
      `.split(" ");
        const printWordByWord = async () => {
          for (const word of markdownContent) {
            const randomTimeout = Math.floor(Math.random() * 5) + 20;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            update_last_system_message_tokens(`${word} `, setMessages);
            setWriting(false);
          }
        };
        printWordByWord();
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des IDs des documents :", error);
      });
  }

  const option_checkup_entreprise = () => {

    setWriting(true);
    var newMsg = createMessage("", "system", undefined, {}, -2);
    updateMessage(newMsg, setMessages);

    const sentence = "Pour faire le checkup d'une entreprise, veuillez renseigner sa dénomination ou son numéro de Siren :".split(" ");

    const printWordByWord = async () => {
      for (const word of sentence) {
        // Generate a random timeout between 1 and 5 seconds (in milliseconds)
        const randomTimeout = Math.floor(Math.random() * 10) + 60;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    };
    printWordByWord();
  }

  const handleOptionClick = (option) => {
    // console.log(option);
    setDisplayOptionsFormalite(false);
    const newMsg = createMessage(option.name, "user", undefined, {}, -1);
    updateMessage(newMsg, setMessages);
    setSelectedOption({ ...option, displayOptionsFormalite: false });

    switch (option.action) {
      case "formalities":
        option_formalités_d_entreprise();
        break;
      case "effectuer":
        option_effectuer_formalites_d_entreprise();
        break;
      case "suivre":
        option_checkup_entreprise();
        // setDisplayOptionsFormalite(true);
        break;
      case "creation":
        option_creation_formalites();
        // setDisplayOptionsFormalite(true);
        break;
      case "modification":
        option_modification_formalites();
        break;
      case "cessation":
        option_cessation_formalites();
        break;
      case "secretariat":
        option_secretariat_formalites();
        break;
      default:
        console.log(option);
    }
  };

  const handleStarClick = async (rating) => {
    setStarRating(rating);
    if (rating <= 2) {
      setModalEvaluationOpen(true);
      return;
    }

    const evaluationData = {
      question: messages[messages.length - 2].content,
      response: messages[messages.length - 1].content,
      date: new Date(),
      name_model: nameModel,
      model: model,
      version_model: version,
      nb_etoile: rating,
    };

    try {
      console.log("Données d'évaluation à envoyer:", evaluationData);
      const data = await fetchData("evaluation", evaluationData, "POST");
      console.log(data)
      setOldStarRating(rating);
      setModalOpen(true);
    } catch (err) {
      alert("Erreur lors de l'envoi de l'évaluation");
    }

  };

  const onEvaluationSubmit = async (message) => {
    try {
      const evaluationData = {
        question: messages[messages.length - 2].content,
        response: messages[messages.length - 1].content,
        date: new Date(),
        name_model: nameModel,
        model: model,
        version_model: version,
        nb_etoile: starRating,
        msg_evaluation: message,
      };
      console.log("Données d'évaluation à envoyer:", evaluationData);
      const data = await fetchData("evaluation", evaluationData, "POST");
      console.log(data)
      setModalEvaluationOpen(false);
      setModalOpen(true);
      setOldStarRating(starRating);
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'évaluation", error);
      alert("Erreur lors de l'envoi de l'évaluation");
    }
  };


  return (
    <div className="w-[98%]">
      <>
        {/* Gestion de l'affichage d'erreur de connexion avec l'API OpenAI */}
        {index === messages.length - 1 && streamError ? (
          <>
            <div>
              <p className={`text-xl text-red-600 text-left`}>
                Désolé, mais l'api ne répond pas. Veuillez réinitialiser votre question ou réessayer plus tard.
              </p>
              <button
                className="font-medium border-2 border-red-600 text-red-600  rounded px-2 py-1
               hover:bg-red-600 hover:text-white"
                onClick={() => retryMessage()}
              >
                Réinitialiser
              </button>
            </div>
            <div className={`${"text-left"} message__createdAt pr-4 ${!writing && "-ml-8"}`}>
              {moment(createdAt).calendar()}
            </div>
          </>
        ) : (
          <>
            {/* Affichage du contenu du message avec ReactMarkdown pour interpréter le markdown */}
            {content.length > 0 ? (
              <>
                <div className="w-full">
                  <div className={`message__markdown text-left	w-full`}>
                    <ReactMarkdown
                      className={"w-full	space-y-4"}
                      remarkPlugins={[remarkGfm]}
                      components={md_renderers}
                    >
                      {normalizeString(content)}
                    </ReactMarkdown>
                  </div>
                  {/* Affichage conditionnel de l'evation et note ou d'un conseil sous le message */}
                  <div className="pr-4 -ml-8 mt-2">


                    <div className={`${(index === 0) | (writing && index === messages.length - 1) | (message_id < 0) && "hidden"} flex items-center justify-between`}>
                      <label className="block text-lg text-blue-logo-color dark:text-white my-1 mr-4 italic">
                        Cette réponse vous a-t-elle été utile ?
                      </label>
                      <div className="flex">
                        {[...Array(4)].map((_, i) => (
                          <span
                            key={i}
                            onClick={() => handleStarClick(i + 1)}
                            onMouseEnter={() => setHoveredStar(i + 1)}
                            onMouseLeave={() => setHoveredStar(null)}
                            className="cursor-pointer hover:scale-125 transition-transform duration-200"
                          >
                            {starRating > i || hoveredStar > i ? (
                              <FaStar className="text-first-oliver mr-1 w-6 h-6" />
                            ) : (
                              <FaRegStar className="text-first-oliver mr-1 w-6 h-6" />
                            )}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div
                      className={`${(index === 0) | (writing && index === messages.length - 1) | (message_id < 0) && "hidden"} 
                         bg-[#E2E2E2] p-2 rounded-lg flex items-center space-x-2 mt-4`}
                    >
                      <img src={light} className="w-3 m-[1px]" />

                      {version === 0 ? (
                        <p className="text-[0.65em] leading-[1.2em]">
                          Oliver ne se substitue pas aux professionnels du droit,
                          seuls habilités à faire du conseil juridique. N’hésitez
                          pas à vérifier les informations importantes et à nous faire part de vos <span
                            className="font-bold cursor-pointer"
                            onClick={() => setModalContactOpen(!modalContactOpen)}
                          >retours par écrit.</span>
                        </p>
                      ) : (
                        <p className="text-[0.65em] leading-[1.2em]">
                          Oliver ne se substitue pas aux professionnels du droit,
                          seuls habilités à faire du conseil juridique. N’hésitez
                          pas à vérifier les informations importantes et à nous faire part de vos <span
                            className="font-bold cursor-pointer"
                            onClick={() => setModalContactOpen(!modalContactOpen)}
                          >retours par écrit.</span>
                        </p>
                      )}

                      <Modal
                        title={"Contact"}
                        modalOpen={modalContactOpen}
                        setModalOpen={setModalContactOpen}
                        modalSize={"auto"}
                        titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
                      >
                        <Contact setOpen={setModalContactOpen} />
                      </Modal>

                      <Modal
                        title={"Evaluation"}
                        modalOpen={modalEvaluationOpen}
                        setModalOpen={setModalEvaluationOpen}
                        modalSize={"auto"}
                        setStarRating={setStarRating}
                        setHoveredStar={setHoveredStar}
                        titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
                      >
                        <Evaluation onEvaluationSubmit={onEvaluationSubmit} />
                      </Modal>

                      <Modal
                        title="Message"
                        modalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        modalSize={"auto"}
                      >
                        <div className="flex flex-col items-center justify-center p-4">
                          <img src={logoInfogreffe} alt="Logo" className="w-40 mb-6" />
                          <p className="text-center mb-6"> Évaluation enregistrée avec succès. </p>
                          <button
                            onClick={() => setModalOpen(false)}
                            className="signupFormContainerContinuer text-white font-bold"
                          >
                            OK
                          </button>
                        </div>
                      </Modal>

                    </div>
                    {/* Affichage conditionnel du bouton de réservations */}
                    {message_id === -4 && (
                      <>
                        {/* {console.log("AssistantMessage#### selectedOption: ", selectedOption)}
                    {console.log("AssistantMessage#### titleFormalitie: ", selectedOption.titleFormalite)}
                    {console.log("AssistantMessage#### categoryFormalitie: ", selectedOption.action)}
                                           */}
                        <div className={`${writing && "hidden"} flex justify-center items-center w-full my-4`}>
                          <ReservationSection
                            modalLoginOpen={modalLoginOpen}
                            setModalLoginOpen={setModalLoginOpen}
                            modalReservationOpen={modalReservationOpen}
                            setModalReservationOpen={setModalReservationOpen}
                          />
                        </div>
                      </>

                    )}
                    {/* Affichage des documents/articles liés au message */}
                    {documents.length > 0 && (
                      <>
                        <div>
                          {showArticles &&
                            documents.map((document, index) => (
                              <Document key={index} document={document} />
                            ))}
                        </div>
                        <button
                          className={`text-orange-logo-color`}
                          onClick={toggleArticles}
                        >
                          {showArticles
                            ? "Cacher les sources"
                            : "Afficher les sources utilisées"}
                        </button>
                      </>
                    )}
                  </div>


                </div>
                <div className={`${"text-left"} message__createdAt pr-4 ${!writing && "-ml-8"}`}>
                  {moment(createdAt).calendar()}
                </div>
              </>
            ) : (
              <>
                <div className="message__thinking">je compile...</div>
                <div className={`${"text-left"} message__createdAt pr-4`}>
                  {moment(createdAt).calendar()}
                </div>
              </>
            )}


          </>
        )}

      </>
      {/* Affichage conditionnel d'un input pour des informations supplémentaires */}
      {/* Lien vers Infogreffe pour les messages contenant un SIREN */}
      {message_id === -3 && (
        <div>
          <a
            href={`https://www.infogreffe.fr/entreprise/${metadatas.company_siren ? metadatas.company_siren : ""
              }`}
            target="_blank"
            rel="noreferrer"
            className="w-full text-center"
          >
            <p className="text-center  border-[2.5px] border-[#C048FB] rounded-lg py-2 p-1 my-2 font-semibold text-[0.85em] text-[#C048FB] hover:bg-[#C048FB] hover:text-white">
              Aller sur la fiche Infogreffe pour commander un KBIS ou document
              officiel
            </p>
          </a>
        </div>
      )}
      {/* Affichage conditionnel des options formalitées */}
      <div>
        {/* {console.log("AssistantMessage#### displayOptionsFormalite: ", displayOptionsFormalite)}
      {console.log("AssistantMessage#### selectedOption?.displayOptionsFormalite: ", selectedOption?.displayOptionsFormalite)} */}
        {/* {index === 0 && selectedOption?.displayOptionsFormalite && ( */}
        {index === 0 && (
          // <div className="flex sm:w-full w-[95%] justify-center sm:flex-row flex-col mt-4">
          <div className={`flex justify-center w-[95%] sm:flex-row mt-4 ${messages.length > 1 && 'hidden'}`}>
            {/* <button
              className="filter_button_checkup_entreprise m-1"
              onClick={() => handleOptionClick(
                { 'class': 'filter_button_checkup_entreprise', 'name': "CHECKUP D'UNE ENTREPRISE", 'action': 'checkup' }
              )}>CHECKUP D'UNE ENTREPRISE
            </button> */}
            <button
              className="filter_button_suivre_formalite m-1"
              onClick={() => handleOptionClick(
                {
                  'class': 'filter_button_suivre_formalite',
                  'name': "FORMALITÉS JURIDIQUES",
                  'action': 'formalities',
                  'key': index
                }
              )}
            >
              FORMALITÉS JURIDIQUES
            </button>
          </div>
        )}

        {/* {console.log("AssistantMessage#### selectedOption: ", selectedOption)}
        {console.log("AssistantMessage#### displayOptionsFormalite: ", displayOptionsFormalite)}
        {console.log("AssistantMessage#### index: ", index)} */}

        {index === 2 && selectedOption && selectedOption.action === 'formalities' && (
          <div className="flex sm:w-full w-[95%] justify-center sm:flex-row flex-col mt-4">
            <button
              className="filter_button_suivre_formalite m-1"
            // onClick={() => handleOptionClick(
            //   {
            //     'class': 'filter_button_suivre_formalite',
            //     'name': "Réaliser une formalité".toUpperCase(),
            //     'action': 'effectuer',
            //     'key': index
            //   }
            // )}
            > <a href="https://mesformalites.legal/"> {"Réaliser une formalité".toUpperCase()}</a> </button>
            <a
              className="filter_button_suivre_formalite m-1"
              // onClick={() => handleOptionClick(
              //   { 'class': 'filter_button_suivre_formalite', 'name': "Suivre une formalité".toUpperCase(), 'action': 'suivre', 'key': index }
              // )} TODO 
              href="https://www.infogreffe.fr/formalites/rechercher-une-formalite"
              target="_blank"
              rel="noreferrer"
            >{"Suivre une formalité".toUpperCase()}</a>
          </div>
        )}

        {index === 4 && selectedOption && selectedOption.action === 'effectuer' && (
          <div className="flex w-full flex-wrap justify-center mt-4">
            <button
              className="filter_button_suivre_formalite m-1"
              onClick={() => handleOptionClick(
                {
                  'class': 'filter_button_suivre_formalite',
                  'name': "CREATION",
                  'action': 'creation',
                  'key': index
                }
              )}>CREATION</button>
            <button
              className="filter_button_suivre_formalite m-1"
              onClick={() => handleOptionClick(
                { 'class': 'filter_button_suivre_formalite', 'name': "MODIFICATION", 'action': 'modification', 'key': index }
              )}>MODIFICATION</button>
            <button
              className="filter_button_suivre_formalite m-1"
              onClick={() => handleOptionClick(
                { 'class': 'filter_button_suivre_formalite', 'name': "CESSATION", 'action': 'cessation' }
              )}>CESSATION</button>
            <button
              className="filter_button_suivre_formalite m-1"
              onClick={() => handleOptionClick(
                { 'class': 'filter_button_suivre_formalite', 'name': "SECRETARIAT JURIDIQUE", 'action': 'secretariat', 'key': index }
              )}>SECRETARIAT JURIDIQUE</button>
          </div>
        )}

        {index === 4 && selectedOption && selectedOption.action === 'suivre' && (
          <InfosInput />
        )}

        {index === 2 && selectedOption && selectedOption.action === 'checkup' && <InfosInput />}


      </div>

    </div>
  );
};

export default AssistantMessage;
