import React from "react";

import logo_mini from "../../../assets/oliverLogo_mini.png";
import person from "../../../assets/person.png";
import { useAppContext } from "../../../context/AppContext";

const MessagePic = ({ role, writing }) => {
  const { currentUser } = useAppContext();

  return (
    <>
      {role === "system" ? (
        <div className={`system_message__pic ${writing ? "bounce" : ""}`}>
          <div className="">
            <div className="w-8">
              <img src={logo_mini} alt="profile pic" />
            </div>
          </div>
        </div>
      ) : (
        <div className="user_message__pic">
          <div className="avatar">
            <div className="w-8 border rounded-full">
              {currentUser ? (
                <div className="bg-blue-logo-color flex justify-center items-center w-full h-full text-white text-[1.1rem]">
                  {currentUser.displayName[0]}
                </div>
              ) : (
                <img src={person} alt="profile pic" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagePic;
