import { IoIosArrowDown, IoMdCheckmark, IoMdCall } from 'react-icons/io';


const ValidationForm = ({
    categoryFormalitie,
    titleFormalitie,
    Capitalize,
    nextStep,
}) => {

    console.log(categoryFormalitie, titleFormalitie);
    return (
        <>
            {categoryFormalitie && titleFormalitie && (
                <>
                    <p className="leading-5	text-center text-2xl pl-4 font-bold text-first-oliver">
                        Formule d'accompagnement
                    </p>
                    <p className="leading-5 text-center text-xs pl-4 my-4 text-first-oliver">
                        {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)}
                    </p>
                    <p className="leading-5  my-4 justify-center">
                        Notre accompagnement prend en charge les éléments suivants :
                    </p>

                    <div className="overflow-x-auto bg-white p-4 rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500">
                            <tbody>
                                {/* Dossier juridique */}
                                <tr className="bg-white font-bold">
                                    <td className="px-4 py-2">Dossier juridique</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2  border-t border-b">Statuts</td>
                                    <td className="text-center text-gray-400 border-t border-b"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50 text-center">
                                        <div className="bg-gray-400 w-4 h-1 mx-20 rounded-full"></div> {/* Ceci crée un tiret horizontal */}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Dossier complet</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b  bg-sky-50  text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>

                                {/* Formalités administratives */}
                                <tr className="bg-white font-bold">
                                    <td className="px-4 py-2">Formalités administratives</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Validation de votre dossier par un formaliste</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Dépôt du dossier auprès du Greffe</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Inscription au registre des bénéficiaires effectifs (RBE)</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>

                                {/* Accompagnement */}
                                <tr className="bg-while font-bold">
                                    <td className="px-4 py-2">Accompagnement</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Traitement du dossier dans les 24h</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50  text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Garantie anti-rejet du Greffe</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50  text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b">Accompagnement par un formaliste pour constituer votre dossier</td>
                                    <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                    <td className="border-t border-b bg-sky-50 text-center">
                                        <div className="w-6 h-6 bg-black rounded-full mx-20 flex items-center justify-center">
                                            <IoMdCall className="text-white text-xl" />
                                        </div>
                                    </td>
                                </tr>
                                {categoryFormalitie === "creation" && (
                                    <>
                                        <tr>
                                            <td className="px-4 py-2 border-t border-b">Aide au choix du régime fiscal </td>
                                            <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                            <td className="border-t border-b bg-sky-50  text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 border-t border-b">Information sur les obligations légales</td>
                                            <td className="border-t border-b text-center text-gray-400"><IoIosArrowDown className="w-6 h-6 mr-2" /></td>
                                            <td className="border-t border-b bg-sky-50  text-center text-green-400"><IoMdCheckmark className="w-6 h-6 mx-20" /></td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>





                </>

            )}
            <table>

            </table>

            <button
                className="p-2 rounded-lg px-8 bg-blue-logo-color text-white border-2 border-blue-logo-color hover:bg-transparent hover:text-blue-logo-color transition-colors duration-150"
                onClick={nextStep}
            >
                Continuer
            </button>
        </>
    );
};


export default ValidationForm;

