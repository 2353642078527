import React from 'react';
import { useAppContext } from '../../../context/AppContext.js';
import Login from "../../auth/Login.jsx";
import ReservationModal from "./ReservationModel.jsx"
import Modal from "../../Modal.jsx";



function ReservationSection({
  modalLoginOpen,
  setModalLoginOpen,
  modalReservationOpen,
  setModalReservationOpen,
}) {

  const { selectedOption, currentUser } = useAppContext();

  const handleReserveClick = () => {
    currentUser ? setModalReservationOpen(true) : setModalLoginOpen(true)
  }

  const ReservationButton = ({ onReserveClick }) => (
    <div className="flex justify-center items-center w-full mt-4">
      <button
        className="text-[#44c76b] border-[#44c76b] bg-transparent border-2 py-2 px-4 w-full rounded-lg font-bold hover:bg-[#44c76b] hover:text-white transition-colors duration-150"
        onClick={onReserveClick}
      >
        JE RÉSERVE LA FORMALITÉ
      </button>
    </div>
  );


  return (
    <>
      <ReservationButton onReserveClick={handleReserveClick} />
      <Modal
        modalOpen={modalLoginOpen}
        setModalOpen={setModalLoginOpen}
        modalSize="auto"
      >
        <Login message="need_to_be_connected_for_reservation" setModalLoginOpen={setModalLoginOpen} />
      </Modal>
      <ReservationModal
        modalReservationOpen={modalReservationOpen}
        setModalReservationOpen={setModalReservationOpen}
        categoryFormalitie={selectedOption.action}
        titleFormalitie={selectedOption.titleFormalite}
      />
    </>
  );
}

export default ReservationSection;